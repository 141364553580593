import React, {
    useRef,
    useState,
} from "react"

import {
    View,
    Image,
    KeyboardAvoidingView,
    Keyboard,
    TouchableWithoutFeedback,
    Button,
    Platform,
    TouchableOpacity,
} from "react-native"

import {
    Input,
    Text,
} from "react-native-elements"

import DGProgress from "./Component/DGProgress"
import styles from "./Stylesheets/Global"

import TestingViewModel, {
    Test,
} from "../ViewModel/TestingVM"

import Icon from "react-native-vector-icons/FontAwesome5"

let shouldUploadImage = false

const TestEditor = (props) => {
    const {
        image,
        setImage,
        deleteTest,
        doneEditing,
        failTest,
        test,
        progress,
        firestore,
        testingVM,
        selectedUserTestsID,
    } = props

    const [actualBehavior, setActualBehavior] = useState(test?.actualBehavior ?? "")
    const [abError, setABError] = useState("")
    const [imageError, setImageError] = useState("")
    const [confirmingDeletion, setConfirmingDeletion] = useState(false)

    const abRef = useRef()

    const saveFailedTest = async () => {
        const timeStamp = (new Date()).getTime()?.toString()
        if (!actualBehavior.length) {
            setABError("You must enter the actual behavior for failed tests")
            return false
        }

        if (!image && test?.image?.fullImagePath) {
            testingVM.removeTestImage(selectedUserTestsID, test)
        }

        test.actualBehavior = actualBehavior

        if (!TestingViewModel.validateTest(test))
        {
            if(test.actualBehaviorError)
                setABError(test.actualBehaviorError)

            else if(test.imageError)
                setImageError(test.imageError)

            return false   
        }

        clearTestDetails()
        await failTest(test, shouldUploadImage)
        shouldUploadImage = false
    }

    const clearTestDetails = () => {
        setActualBehavior("")
        setABError("")
        setImageError("")
        setConfirmingDeletion(false)
    } 

    const removeImage = async () => {
        setImage(null)
    }

    const initiateTestDeletion = () => {
        setConfirmingDeletion(true)
    }

    const cancelTestDeletion = () => {
        setConfirmingDeletion(false)
    }

    const confirmTestDeletion = async () => {
        if (test?.image?.fullImagePath)
            await firestore?.deleteImage(test.image.fullImagePath)

        await deleteTest(test)
        setConfirmingDeletion(false)
        clearTestDetails()
        await doneEditing()
    }

    const handleKeyboardDismiss = () => {
        if (Platform.OS !== "web")
            Keyboard.dismiss()
    }

    return (
        <View style={styles.modal}>
            <View 
                style={{
                    width: "100%",
                    textAlign: "right"
                }}
            >
                <Icon 
                    name="times" 
                    size={30} 
                    color="black"
                    onPress={
                        () => {
                            doneEditing()
                            clearTestDetails()
                        }
                    }
                />
            </View>
            <KeyboardAvoidingView>
                <TouchableWithoutFeedback
                    onPress={handleKeyboardDismiss}
                >
                    <View
                        style={styles.modalUI}
                    >
                        <Text h3>
                            Test Details
                        </Text>
                        <View style={{
                            padding: 8,
                            height: 80,
                            justifyContent: "space-evenly"
                        }}>
                            <Text>
                                Description: {test?.case?.description}
                            </Text>
                            <Text>
                                Expected Behavior: {test?.case?.expectedBehavior}
                            </Text>
                        </View>
                        <Input 
                            placeholder="Actual Behavior"
                            value={actualBehavior}
                            onChangeText={setActualBehavior}
                            numberOfLines={4}
                            multiline={true}
                            errorMessage={abError}
                            style={{
                                minHeight: 100,
                            }}
                        />
                        {!confirmingDeletion && (
                            <Button
                                title="Delete Test"
                                onPress={initiateTestDeletion}
                                color="rgb(255, 0, 80)"
                            />
                        )}
                        {confirmingDeletion && (
                            <>
                                <Button
                                    title="Confirm: Delete Test?"
                                    onPress={confirmTestDeletion}
                                    color="rgb(255, 0, 80)"
                                />
                                <Button
                                    title="Do Not Delete"
                                    onPress={cancelTestDeletion}
                                />
                            </>
                        )}
                        {image ? (
                            <View>
                                {progress > 0 && progress < 100 && <DGProgress progress={progress} />}
                                <TouchableOpacity>
                                    <Image 
                                        source={{ 
                                            uri: image 
                                        }} 
                                        style={styles.modalImage} 
                                    />
                                </TouchableOpacity>
                                <Button 
                                    title="Remove Image"
                                    onPress={removeImage}
                                />
                            </View>
                        ) : (
                            <View style={{
                                height: 200,
                            }} />
                        )}
                        {!image && (
                            <>
                                <Button 
                                    title="Upload Results Image From File" 
                                    onPress={
                                        ()=>{
                                            shouldUploadImage = true
                                            testingVM.pickImage(setImage)
                                        }
                                    }
                                    type="outline"
                                />
                                
                                {Platform.OS !== "web" && (
                                    <Button 
                                        title="Upload Results Image From Camera" 
                                        onPress={
                                            ()=>{
                                                shouldUploadImage = true
                                                testingVM.takePhoto(setImage)
                                            }
                                        }
                                        type="outline"
                                    />
                                )}
                            </>
                        )}
                        <Button 
                            title="Save as Failed Test" 
                            onPress={saveFailedTest}
                            type="outline"
                        />
                        <Button
                            title="Cancel"
                            onPress={
                                () => {
                                    doneEditing()
                                    clearTestDetails()
                                }
                            }
                            color="rgb(255, 0, 80)"
                        />
                    </View>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
        </View>
    )
}

export default TestEditor