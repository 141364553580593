import React, {
    useEffect,
    useState,
} from "react"

import { 
    ActivityIndicator,
    View,
    Platform,
    ScrollView,
    TouchableOpacity,
} from "react-native"

import {
    Text,
    Divider,
    Button,
} from "react-native-elements"

import Icon from "react-native-vector-icons/FontAwesome5"

import {
    Picker
} from "@react-native-picker/picker"

import DGCSVDownload from "./Component/DGCSVDownload"
import TestingViewModel from "../ViewModel/TestingVM"
import styles from "./Stylesheets/Global"
import DGTestCaseRow from "./Component/DGTestCaseRow"
import * as ImagePicker from "expo-image-picker"


const Admin = (props) => {
    const {
        firestore,
    } = props

    const [status, requestPermission] = ImagePicker.useCameraPermissions()
    const [userTests, setUserTests] = useState({})
    const [testCases, setTestCases] = useState([])
    const [csvData, setCSVData] = useState([])
    const [csvName, setCSVName] = useState("")
    const [showCases, setShowCases] = useState(false)
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [VM, setVM] = useState(undefined)
    const [sortFilter, _] = useState("description")
    const [selectedUserTests, setSelectedUserTests] = useState("none")

    const sortTests = (a, b) => {
        if(a.case[sortFilter] < b.case[sortFilter])
            return -1
        else if(a[sortFilter] > b[sortFilter])
            return 1

        return 0
    }

    const sortTableData = (t) => {
        if (t && !!Object.keys(t)?.length) {
            const tD = Object.values(t)
            tD.sort(sortTests)
            setTableData(tD)
        }
    }

    const loadData = async () => {
        const testingVM = new TestingViewModel(firestore, requestPermission, status)
        setLoading(true)
        setVM(testingVM) 
        const {
            userTests,
            testCases,
        } = await testingVM.loadAdminData()
        setLoading(false)

        const uT = {}
        userTests.forEach(
            doc => {
                Object.keys(doc).forEach(
                    userTestsID => {
                        uT[userTestsID] = { ...doc[userTestsID] }
                    }
                )
            }
        )

        let tC = []
        testCases.forEach(
            c => tC = [...tC, ...Object.values(c)]
        )
        tC.sort((a, b) => a.createdAt - b.createdAt)
        setTestCases(tC)
        setUserTests(uT)

        if (uT[selectedUserTests])
            sortTableData(uT[selectedUserTests]?.tests)
        else
            setTableData([])
    }

    const openTestCases = () => {
        setShowCases(true)
    }

    const closeTestCases = () => {
        setShowCases(false)
    }

    useEffect(
        () => {
            loadData()
        }, [firestore]
    )

    return (
        <>
            {!showCases && (
                <ScrollView style={styles.container}>
                    <View 
                        style={styles.tableViewHeader}
                    >
                        <Text h3 style={styles.header}>Admin Panel</Text>
                    </View>
                    <Divider />
                    <View
                        style={{
                            flexDirection: "row",
                            paddingTop: 10,
                            paddingHorizontal: 0,
                            flex: 1,
                        }}
                    >
                        <View
                            style={{
                                flex: 4,
                                alignItems: "flex-start",
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                {Platform.OS === "web" && (
                                    <View style={{ marginLeft: 8 }} />
                                )}
                                <Picker
                                    selectedValue={selectedUserTests}
                                    itemStyle={{
                                        height: 45,
                                    }}
                                    style={styles.dgPickerStyle}
                                    onValueChange={
                                        (itemValue) => {
                                            setSelectedUserTests(itemValue)
                                            if (itemValue === "none") {
                                                setTableData([])
                                            } else {
                                                if (userTests[itemValue]) {
                                                    setCSVData(VM.getTestsAsCSV(userTests[itemValue]))
                                                    setCSVName(`${userTests[itemValue].softwareName}-tests-${(new Date(parseInt(userTests[itemValue].updatedAt)).toISOString())}.csv`)
                                                    sortTableData(userTests[itemValue]?.tests)
                                                }
                                            }
                                        }
                                    }
                                >
                                    <Picker.Item label="Select User Tests" value="none" />
                                    
                                    {!!Object.keys(userTests).length && Object.entries(userTests).map(
                                        ([userTestsID, userTestsObject]) => (
                                            <Picker.Item
                                                label={`${userTestsObject.softwareName} tests on ${(new Date(parseInt(userTestsObject.updatedAt)).toISOString())}`}
                                                value={userTestsID}
                                                key={userTestsID}
                                            />
                                        )
                                    )}
                                </Picker>
                            </View>
                        </View>
                        <View
                            style={{
                                flex: 2, 
                                alignItems: "flex-end",
                                marginRight: 5,
                            }}
                        >
                            <TouchableOpacity
                                onPress={openTestCases}
                                style={[styles.dgButton]}
                            >
                                <Text
                                    style={styles.dgButtonText}
                                >
                                    Test Cases
                                </Text>
                                <Icon 
                                    name="vial" 
                                    size={20} 
                                    color="rgb(255,50,50)"
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                    {!loading && !tableData.length && (
                        <View>
                            <Text 
                                h4 
                                style={{ 
                                    textAlign: "center", 
                                    padding: 20,
                                }}>
                                No Software Tests Are Selected
                            </Text>
                        </View>
                    )}
                    {loading ? <ActivityIndicator style={styles.loading} size="large" /> : (
                        !!tableData?.length && tableData.map(
                            test => (
                                <DGTestCaseRow 
                                    key={test.case.id}
                                    testCase={test.case}
                                    test={test}
                                    headerButtons={[{
                                        name: "dumpster-fire",
                                        action: ()=>{/**/},
                                        size: 25,
                                        color: test.pass === false ? "rgb(255,50,50)" : "black"
                                    }, {
                                        name: "check",
                                        action: ()=>{/**/},
                                        size: 25,
                                        color: test.pass ? "limegreen" : "black"
                                    }]}
                                />
                            )
                        )
                    )}
                    {!loading && !!tableData?.length && (
                        <DGCSVDownload 
                            data={csvData}
                            filename={csvName}
                        />
                    )}
                    <View
                        style={{
                            marginBottom: 120,
                        }}
                    />
                </ScrollView>
            )}
            {showCases && !!testCases.length && (
                <ScrollView>
                    <Button
                        onPress={closeTestCases}
                        title={"Close Test Cases"}
                    />
                    {testCases.map(
                        testCase => (
                            <DGTestCaseRow
                                key={testCase.id}
                                testCase={testCase}
                            />
                        )
                    )}
                </ScrollView>
            )}
        </>
    )
}

export default Admin