import {
    StyleSheet,
} from "react-native"

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 30,
        marginBottom: 30,
    },
    error: {
        fontSize: 18,
        color: "red",
        padding: 10,
        maxWidth: 300,
    },
    logo: {
        width: 300,
        height: 300,
    },
    input: {
        fontSize: 20,
        padding: 15
    },
    wrapper: {
        padding: 24,
        flex: 1,
        justifyContent: "space-around"
    },
    helpLink: { 
        fontWeight: "200", 
        fontSize: 17, 
        textAlign: "center", 
        paddingTop: 20 
    },
    header: {
        paddingLeft: 15
    }
})

export default styles