import {
    CSVLink,
} from "react-csv"

import {
    Button,
} from "react-native-elements"

const DGCSVDownload = ({ data, filename }) => (
    <CSVLink 
        data={
            data
        }
        filename={
            filename
        }
        style={{
            textDecoration: "none",
        }}
    >
        
        <Button
            title="Export CSV File"
            onPress={() => { /**/ }}
        />          
    </CSVLink>
)

export default DGCSVDownload