import React, {
    useState,
    useEffect,
} from "react"

import {
    View,
    KeyboardAvoidingView,
    Keyboard,
    TouchableWithoutFeedback,
    Button,
    Platform,
    
} from "react-native"

import {
    Input,
    Text,
    CheckBox,
} from "react-native-elements"

import styles from "./Stylesheets/Global"

import TestingViewModel, {
    TestSuite,
} from "../ViewModel/TestingVM"

import Icon from "react-native-vector-icons/FontAwesome5"

const TestSuiteEditor = (props) => {
    const {
        doneEditing,
        addTestSuite,
        testSuite,
        firestore,
        allTestCases,
    } = props

    const [description, setDescription] = useState(testSuite?.description ?? "")
    const [descriptionError, setDescriptionError] = useState("")
    const [confirmingDeletion, setConfirmingDeletion] = useState(false)
    const [selectedTestCaseMap, setSelectedTestCaseMap] = useState({})
    const [testCases, setTestCases] = useState(allTestCases)

    const isEditing = typeof testSuite !== "undefined"

    const saveTestSuite = async () => {
        const timeStamp = (new Date()).getTime()?.toString()
        const newTestSuite: TestSuite = {
            description,
            id: testSuite?.id,
            createdAt: testSuite?.createdAt ?? timeStamp,
            updatedAt: timeStamp,
            testCases: []
        }

        Object.entries(selectedTestCaseMap).forEach(
            ([tID, isSelected]) => {
                if (isSelected)
                    newTestSuite.testCases.push(tID)
            }
        )

        if (!TestingViewModel.validateTestSuite(newTestSuite)) {
            setDescriptionError(newTestSuite?.descriptionError)
            return false
        }

        clearTestSuiteDetails()
        await addTestSuite(newTestSuite)
    }

    const clearTestSuiteDetails = () => {
        setDescription("")
        setConfirmingDeletion(false)
    } 

    const initiateTestSuiteDeletion = () => {
        setConfirmingDeletion(true)
    }

    const cancelTestSuiteDeletion = () => {
        setConfirmingDeletion(false)
    }

    const confirmTestSuiteDeletion = async () => {
        await firestore?.deleteDocField("TestSuite", firestore?.user?.uid, testSuite?.id)
        setConfirmingDeletion(false)
        clearTestSuiteDetails()
        await doneEditing()
    }

    const loadData = async () => {
        const tC = { ...testCases }
        const selected = { ...selectedTestCaseMap }
        Object.keys(tC).forEach(
            tID => {
                selected[tID] = Boolean(testSuite?.testCases?.includes(tID))
            }
        )
        setSelectedTestCaseMap(selected)
    }

    const handleTestCaseSelection = (tID) => {
        const selected = { ...selectedTestCaseMap }
        selected[tID] = !selected[tID]
        setSelectedTestCaseMap(selected)
    }

    const search = (needle) => {
        if (!needle) {
            setTestCases(allTestCases)
            return
        }
        const results = {}
        const hayStack = Object.values(allTestCases)

        hayStack.forEach(
            tC => {
                if (tC?.description?.toLowerCase()?.includes(needle?.toLowerCase()))
                    results[tC.id] = tC
            }
        )
        setTestCases(results)
    }

    useEffect(
        () => {
            loadData()
        }, []
    )

    const handleKeyboardDismiss = () => {
        if (Platform.OS !== "web")
            Keyboard.dismiss()
    }

    return (
        <View style={styles.modal}>
            <View 
                style={{
                    width: "100%",
                    textAlign: "right"
                }}
            >
                <Icon 
                    name="times" 
                    size={30} 
                    color="black"
                    onPress={
                        () => {
                            doneEditing()
                            clearTestSuiteDetails()
                        }
                    }
                />
            </View>
            <KeyboardAvoidingView>
                <TouchableWithoutFeedback
                    onPress={handleKeyboardDismiss}
                >
                    <View>
                        <View
                            style={
                                [styles.modalUI, {
                                    height:300
                                }]
                            }
                        >
                            <Text h3>
                                {isEditing ? "Edit" : "Add"} Test Suite
                            </Text>
                            <Input 
                                placeholder="Description"
                                returnKeyType="next"
                                value={description}
                                onChangeText={setDescription}
                                errorMessage={descriptionError}
                                onSubmitEditing={() => {/**/}}
                            />
                            {isEditing && !confirmingDeletion && (
                                <Button
                                    title="Delete Test Suite"
                                    onPress={initiateTestSuiteDeletion}
                                    color="rgb(255, 0, 80)"
                                />
                            )}
                            {isEditing && confirmingDeletion && (
                                <>
                                    <Button
                                        title="Confirm: Delete Test Suite?"
                                        onPress={confirmTestSuiteDeletion}
                                        color="rgb(255, 0, 80)"
                                    />
                                    <Button
                                        title="Do Not Delete"
                                        onPress={cancelTestSuiteDeletion}
                                    />
                                </>
                            )}
                            <Button 
                                title="Save" 
                                onPress={saveTestSuite}
                                type="outline"
                            />
                            <Button
                                title="Cancel"
                                onPress={
                                    () => {
                                        doneEditing()
                                        clearTestSuiteDetails()
                                    }
                                }
                                color="rgb(255, 0, 80)"
                            />
                        </View>
                        {!!Object.keys(allTestCases).length ? (
                            <>
                                <Text h4>Select Test Cases</Text>
                                <Input 
                                    placeholder="Search for Test Cases to Add"
                                    onChangeText={search}
                                    rightIcon={{ type: "font-awesome", name: "search" }}
                                />
                            </>
                        ) : <Text>No Test Cases...</Text>}
                        {!!Object.keys(testCases).length && Object.entries(testCases).map(
                            ([testCaseID, testCase]) => (
                                <View
                                    key={testCaseID}
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <CheckBox
                                        left
                                        checked={selectedTestCaseMap[testCaseID]}
                                        onPress={()=>handleTestCaseSelection(testCaseID)}
                                    />
                                    <Text>
                                        {testCase.description}
                                    </Text>
                                </View>
                            )
                        )}
                    </View>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
        </View>
    )
}

export default TestSuiteEditor