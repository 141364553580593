import React, {
    useRef,
    useState,
} from "react"

import {
    View,
    Image,
    KeyboardAvoidingView,
    Keyboard,
    TouchableWithoutFeedback,
    Button,
    Platform,
} from "react-native"

import {
    Input,
    Text,
} from "react-native-elements"

import DGProgress from "./Component/DGProgress"
import styles from "./Stylesheets/Global"

import Icon from "react-native-vector-icons/FontAwesome5"

import TestingViewModel, {
    TestCase,
} from "../ViewModel/TestingVM"

let shouldUploadImage = false

const TestCaseEditor = (props) => {
    const {
        image,
        setImage,
        doneEditing,
        addTestCase,
        testCase,
        progress,
        firestore,
        testingVM,
    } = props

    const [description, setDescription] = useState(testCase?.description ?? "")
    const [expectedBehavior, setExpectedBehavior] = useState(testCase?.expectedBehavior ?? "")
    const [descriptionError, setDescriptionError] = useState("")
    const [ebError, setEBError] = useState("")
    const [confirmingDeletion, setConfirmingDeletion] = useState(false)

    const ebRef = useRef()

    const isEditing = typeof testCase !== "undefined"

    const saveTestCase = async () => {
        const timeStamp = (new Date()).getTime()?.toString()
        const newTestCase: TestCase = {
            description,
            expectedBehavior,
            id: testCase?.id,
            createdAt: testCase?.createdAt ?? timeStamp,
            updatedAt: timeStamp,
        }
        if (!image && testCase?.image?.fullImagePath) {
            await firestore?.deleteImage(testCase.image.fullImagePath)
            await firestore?.deleteDocField("TestCase", firestore.user.uid, `${testCase.id}.image`)
        } else if (testCase?.image) {
            newTestCase.image = testCase.image
        }

        if (!TestingViewModel.validateTestCase(newTestCase))
        {
            if(newTestCase.expectedBehaviorError)
                setEBError(newTestCase.expectedBehaviorError)
            if(newTestCase.descriptionError)
                setDescriptionError(newTestCase.setDescriptionError)

            return false   
        }

        clearTestCaseDetails()
        await addTestCase(newTestCase, shouldUploadImage)
        shouldUploadImage = false
    }

    const clearTestCaseDetails = () => {
        setDescription("")
        setExpectedBehavior("")
        setConfirmingDeletion(false)
    } 

    const removeImage = async () => {
        setImage(null)
        shouldUploadImage = false
    }

    const initiateTestCaseDeletion = () => {
        setConfirmingDeletion(true)
    }

    const cancelTestCaseDeletion = () => {
        setConfirmingDeletion(false)
    }

    const confirmTestCaseDeletion = async () => {
        if (testCase?.image?.fullImagePath)
            await firestore?.deleteImage(testCase.image.fullImagePath)

        await firestore?.deleteDocField("TestCase", firestore?.user?.uid, testCase?.id)
        setConfirmingDeletion(false)
        clearTestCaseDetails()
        await doneEditing()
    }

    const handleKeyboardDismiss = () => {
        if (Platform.OS !== "web")
            Keyboard.dismiss()
    }

    return (
        <View style={styles.modal}>
            <View 
                style={{
                    width: "100%",
                    textAlign: "right"
                }}
            >
                <Icon 
                    name="times" 
                    size={30} 
                    color="black"
                    onPress={
                        () => {
                            doneEditing()
                            clearTestCaseDetails()
                        }
                    }
                />
            </View>
            <KeyboardAvoidingView>
                <TouchableWithoutFeedback
                    onPress={handleKeyboardDismiss}
                >
                    <View
                        style={styles.modalUI}
                    >
                        <Text h3>
                            {isEditing ? "Edit" : "Add"} Test Case
                        </Text>
                        <Input 
                            placeholder="Description"
                            returnKeyType="next"
                            value={description}
                            onChangeText={setDescription}
                            errorMessage={descriptionError}
                            onSubmitEditing={() => ebRef.current.focus()}
                        />
                        <Input 
                            placeholder="Expected Behavior"
                            ref={ebRef}
                            value={expectedBehavior}
                            onChangeText={setExpectedBehavior}
                            numberOfLines={4}
                            multiline={true}
                            errorMessage={ebError}
                            style={{
                                minHeight: 100,
                            }}
                        />
                        {isEditing && !confirmingDeletion && (
                            <Button
                                title="Delete Test Case"
                                onPress={initiateTestCaseDeletion}
                                color="rgb(255, 0, 80)"
                            />
                        )}
                        {isEditing && confirmingDeletion && (
                            <>
                                <Button
                                    title="Confirm: Delete Test Case?"
                                    onPress={confirmTestCaseDeletion}
                                    color="rgb(255, 0, 80)"
                                />
                                <Button
                                    title="Do Not Delete"
                                    onPress={cancelTestCaseDeletion}
                                />
                            </>
                        )}
                        {image ? (
                            <View>
                                {progress > 0 && progress < 100 && <DGProgress progress={progress} />}
                                <Image 
                                    source={{ 
                                        uri: image 
                                    }} 
                                    style={styles.modalImage} 
                                />
                                <Button 
                                    title="Remove Image"
                                    onPress={removeImage}
                                />
                            </View>
                        ) : (
                            <View style={{
                                height: 200,
                            }} />
                        )}
                        {!image && (
                            <>
                                <Button 
                                    title="Upload Expectations Image From File" 
                                    onPress={()=>{
                                        shouldUploadImage = true
                                        testingVM.pickImage(setImage)
                                    }}
                                    type="outline"
                                />
                                
                                {Platform.OS !== "web" && (
                                    <Button 
                                        title="Upload Expectations Image From Camera" 
                                        onPress={()=>{
                                            shouldUploadImage = true
                                            testingVM.takePhoto(setImage)
                                        }}
                                        type="outline"
                                    />
                                )}
                            </>
                        )}
                        <Button 
                            title="Save" 
                            onPress={saveTestCase}
                            type="outline"
                        />
                        <Button
                            title="Cancel"
                            onPress={
                                () => {
                                    doneEditing()
                                    clearTestCaseDetails()
                                }
                            }
                            color="rgb(255, 0, 80)"
                        />
                    </View>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
        </View>
    )
}

export default TestCaseEditor