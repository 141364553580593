import {
    ActivityIndicator,
    View
} from "react-native"

const LoadingView = () => {
    return (
        <View style={{
            height: "100%",
            justifyContent: "center",
        }}>
            <ActivityIndicator size="large" />
        </View>
    )
}

export default LoadingView