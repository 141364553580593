import React from "react"
import {
    Text,
    ActivityIndicator,
    View,
} from "react-native"

const DGProgress = (props) => {

    const {
        progress,
    } = props

    return (
        <View 
            style={{
                justifyContent: "center"
            }}
        >
            {progress > 0 && progress < 100 && (
                <View 
                    style={{
                        padding: 10,
                    }}
                >
                    <ActivityIndicator 
                        size="large" 
                    />
                </View>
            )}
        </View>
    )
}

export default DGProgress