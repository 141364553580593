import DGViewModel from "./DGViewModel"

class ProjectTrackerViewModel extends DGViewModel{

    os: string

    constructor(db: any, os: string) {
        super(db)
        this.os = os
    }

    pad(num: string, size=2) {
        while (num.length < size) num = "0" + num
        return num
    }
    
    formatDate(date: string) {
        const d = new Date(date)
        let ds: string

        if (this.os === "ios" || this.os === "android") {
            const dsArray = d.toLocaleDateString("en-US").split("/")
            ds = `${dsArray[2]}-${this.pad(dsArray[0])}-${this.pad(dsArray[1])}`
        } else {
            ds = date
        }
        return [d, ds]
    }

    getProjectData = async (startDateString: string, endDateString: string, page: number) => {
        return await this.makeAPICall({
            startDate: startDateString,
            endDate: endDateString,
            page: page
        }, "projectTracker")
    }

    generateCoefficientsForProject(jiraData: object, project: string): object {
        const userHoursAndStoryPoints = {}
        Object.keys(jiraData).forEach(
            (jiraID) => {
                if(jiraID.includes(project) && jiraData[jiraID].assignee && jiraData[jiraID].status === "Done") {
                    const assignee = jiraData[jiraID].assignee.displayName
                    if(!userHoursAndStoryPoints[assignee]) {
                        userHoursAndStoryPoints[assignee] = [0.0, 0.0]
                    }
                    userHoursAndStoryPoints[assignee][0] += jiraData[jiraID].totalHours
                    userHoursAndStoryPoints[assignee][1] += jiraData[jiraID].storyPoints
                }
            }
        )

        const response = {}
        Object.keys(userHoursAndStoryPoints).forEach(
            (user) => {
                response[user] = userHoursAndStoryPoints[user][0] / userHoursAndStoryPoints[user][1]
            }
        )
        return response
    }

    generateCoefficientsAcrossAllProjects(jiraData: object, projects: string[]): object {
        const responses = []
        projects.forEach(
            (project) => {
                const projectResult = this.generateCoefficientsForProject(jiraData, project)
                responses.push(projectResult)
            }
        )
        const r = {}
        const assigneesCounts = {}
        responses.forEach(
            response => {
                const assignees = Object.keys(response)
                assignees.forEach(
                    (assignee) => {
                        if(!r[assignee]) {
                            r[assignee] = 0.0
                            assigneesCounts[assignee] = 0
                        }
                        r[assignee] += response[assignee]
                        assigneesCounts[assignee]++
                    }
                )
            }
        )
        const response = {}
        Object.keys(r).forEach(
            (a) => {
                response[a] = r[a] / assigneesCounts[a] 
            }
        )
        return response
    }
}

export default ProjectTrackerViewModel