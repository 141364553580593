/**
 * @file Navigation.js - Navigation manager for the application.
 */

import React, { useState, cloneElement } from "react"
import { Header, Button, Image } from "react-native-elements"
import { View } from "react-native"
import { createDrawerNavigator } from "@react-navigation/drawer"
import { NavigationContainer } from "@react-navigation/native"
import Icon from "react-native-vector-icons/FontAwesome"

//  import LogOutButton from "./Auth"
 
/**
 * This handles the "slide menu" and navigation state.
 */
const Drawer = createDrawerNavigator()
 
const NavigationRoot = (props) => {
    const {
        pages, 
        initialIndex,
    } = props
    
    const screenLinks = []
    let key = 0
    for (const page of pages) {
        screenLinks.push(
            <Drawer.Screen
                key={key}
                name={page.name}
            >
                {/* eslint-disable-next-line */}
                {(props) => <AppHeader {...props} page={page.component} />}
            </Drawer.Screen>
        )
        key++
    }

    return (
        <Drawer.Navigator 
            headerMode="float" 
            initialRouteName={pages[initialIndex].name}
        >
            {screenLinks}
        </Drawer.Navigator>
    )
}
 

const AppHeader = (props) => {
    const [image, setImage] = useState(props.image ? { uri: props.image } : require("../../assets/navLogo.png"))
    const { navigation, page } = props
    return (
        <>
            <Header
                leftComponent={<HamburgerButton navigation={navigation} size={32} fillColor="#FFF" />}
                centerComponent={<Image source={image} style={{ width: 50,height: 50 }}/>}
                backgroundColor={"black"}
            />
            {cloneElement(page, { navigation:navigation })}
        </>
    )
}
 
const HamburgerButton = (props) => {
    const { size, fillColor, navigation } = props
    return (
        <Button
            onPress={() => navigation.toggleDrawer()}
            type="clear"
            icon={(
                <Icon
                    name="bars"
                    size={size}
                    color={fillColor}
                />
            )}
        />
    )
}

export default NavigationRoot