// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB3xPIMDrlBmbvmHo2wlzUXF-nFqVnKGiw",
    authDomain: "datageememberportal.firebaseapp.com",
    projectId: "datageememberportal",
    storageBucket: "datageememberportal.appspot.com",
    messagingSenderId: "23337102495",
    appId: "1:23337102495:web:e9c1d6f411d73229a106aa",
    measurementId: "G-HR75FQV4SK"
}

export default firebaseConfig