import React, {
    useEffect,
    useState,
} from "react"

import {
    View,
    KeyboardAvoidingView,
    Keyboard,
    TouchableWithoutFeedback,
    Button,
    Platform,
} from "react-native"

import {
    Input,
    Text,
    CheckBox,
} from "react-native-elements"

import styles from "./Stylesheets/Global"

import TestingViewModel, {
    UserTests,
    Test,
} from "../ViewModel/TestingVM"

import Icon from "react-native-vector-icons/FontAwesome5"

const UserTestsEditor = (props) => {
    const {
        doneEditing,
        addUserTests,
        allTestCases,
        allTestSuites,
        allTestSuiteGroups,
        userTests,
        firestore,
        newUserTest,
    } = props

    const [selectedTestCaseMap, setSelectedTestCaseMap] = useState({})
    const [softwareName, setSoftwareName] = useState(userTests?.softwareName ?? "")
    const [softwareNameError, setSoftwareNameError] = useState("")
    const [testsError, setTestsError] = useState("")
    const [testCases, setTestCases] = useState(allTestCases)
    const [showTestSuites, setShowTestSuites] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [isEditing, setIsEditing] = useState(typeof userTests !== "undefined")
    const [confirmingDeletion, setConfirmingDeletion] = useState(false)

    const saveUserTests = async () => {
        const timeStamp = (new Date()).getTime()?.toString()
        const newUserTests: UserTests = {
            id: userTests?.id,
            tests: {},
            softwareName: userTests?.softwareName ?? softwareName,
            createdAt: userTests?.createdAt ?? timeStamp,
            updatedAt: timeStamp,
        }
        console.log(newUserTests)
        Object.entries(selectedTestCaseMap).forEach(
            ([tID, isSelected]) => {
                if (isSelected) {
                    const newTest: Test = {
                        case: testCases[tID]
                    }

                    newUserTests.tests[tID] = isEditing && userTests?.tests[tID] ? userTests.tests[tID] : newTest
                }
            }
        )
        console.log(newUserTests)
        if (!TestingViewModel.validateUserTests(newUserTests))
        {
            if(newUserTests.testsError)
                setTestsError(newUserTests.testsError)
            if(newUserTests.softwareNameError)
                setSoftwareNameError(newUserTests.softwareNameError)

            return false   
        }

        clearUserTestsDetails()
        await addUserTests(newUserTests)
    }

    const createNewUserTests = async () => {
        saveUserTests()
    }

    const clearUserTestsDetails = () => {
        setSoftwareName("")
        setConfirmingDeletion(false)
        const selected = { ...selectedTestCaseMap }
        Object.entries(selected).forEach(
            ([tID, t]) => {
                selected[tID] = false
            }
        )
        setSelectedTestCaseMap(selected)
    } 

    const initiateUserTestsDeletion = () => {
        setConfirmingDeletion(true)
    }

    const cancelUserTestsDeletion = () => {
        setConfirmingDeletion(false)
    }

    const confirmUserTestsDeletion = async () => {
        await firestore?.deleteDocField("UserTests", firestore?.user?.uid, userTests?.id)
        setConfirmingDeletion(false)
        clearUserTestsDetails()
        await doneEditing()
    }

    const loadData = async () => {
        const tC = { ...testCases }
        const selected = { ...selectedTestCaseMap }
        Object.entries(tC).forEach(
            ([tID, t]) => {
                selected[tID] = Boolean(userTests?.tests && userTests?.tests[tID])
            }
        )
        setSelectedTestCaseMap(selected)
    }

    const handleTestCaseSelection = (tID) => {
        const selected = { ...selectedTestCaseMap }
        selected[tID] = !selected[tID]
        setSelectedTestCaseMap(selected)
    }

    const search = (needle) => {
        if (!needle) {
            setTestCases(allTestCases)
            return
        }
        const results = {}
        const hayStack = Object.values(allTestCases)

        hayStack.forEach(
            tC => {
                if (tC?.description?.toLowerCase()?.includes(needle?.toLowerCase()))
                    results[tC.id] = tC
            }
        )
        setTestCases(results)
    }

    const toggleTestSuites = () => {
        setShowTestSuites(!showTestSuites)
    }

    const toggleOptions = () => {
        setShowOptions(!showOptions)
    }

    useEffect(
        () => {
            loadData()            
        }, []
    )

    const handleKeyboardDismiss = () => {
        if (Platform.OS !== "web")
            Keyboard.dismiss()
    }    

    return (
        <View style={styles.modal}>
            <KeyboardAvoidingView>
                <TouchableWithoutFeedback
                    onPress={handleKeyboardDismiss}
                >
                    <View
                        style={styles.modalUI}
                    >
                        <View 
                            style={{
                                width: "100%",
                                textAlign: "right"
                            }}
                        >
                            <Icon 
                                name="times" 
                                size={30} 
                                color="black"
                                onPress={
                                    () => {
                                        doneEditing()
                                        clearUserTestsDetails()
                                    }
                                }
                            />
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Text h3 style={{
                                flex: 4,
                            }}>
                                {isEditing ? "Edit" : "Add"} Software Test
                            </Text>
                            {isEditing && (
                                <Button
                                    title={showOptions ? "Hide Options" : "Options"}
                                    onPress={toggleOptions}
                                />
                            )}
                        </View>
                        <View
                            style={{
                                flexDirection: "column"
                            }}
                        >
                            {isEditing && showOptions && (
                                <>
                                    <Button
                                        title="Create New From This Test"
                                        onPress={()=>{
                                            const selected = { ...selectedTestCaseMap }
                                            setIsEditing(false)
                                            newUserTest()
                                            Object.keys(allTestCases).forEach(
                                                testCaseID => {
                                                    selected[testCaseID] = false
                                                }
                                            )
                                            Object.keys(userTests?.tests)?.forEach(
                                                testCaseID => {
                                                    selected[testCaseID] = true
                                                }
                                            )
                                            setSelectedTestCaseMap(selected)
                                        }}
                                    />
                                    <Button
                                        title="Create New Blank Test"
                                        onPress={()=>{
                                            clearUserTestsDetails()
                                            newUserTest()
                                        }}
                                    />
                                    {!confirmingDeletion && userTests && (
                                        <Button
                                            title="Delete Software Test"
                                            onPress={initiateUserTestsDeletion}
                                            color="rgb(255, 0, 80)"
                                        />
                                    )}
                                    {confirmingDeletion && userTests && (
                                        <>
                                            <Button
                                                title="Confirm: Delete Software Test?"
                                                onPress={confirmUserTestsDeletion}
                                                color="rgb(255, 0, 80)"
                                            />
                                            <Button
                                                title="Do Not Delete"
                                                onPress={cancelUserTestsDeletion}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </View>
                        <Input 
                            placeholder="Software Name"
                            value={softwareName}
                            onChangeText={setSoftwareName}
                            errorMessage={softwareNameError}
                        />
                        {!!testsError && (
                            <Text style={{
                                color: "red",
                                textAlign: "center",
                            }}>
                                {testsError}
                            </Text>
                        )}
                        <Button 
                            title="Save" 
                            onPress={saveUserTests}
                            type="outline"
                        />
                        <Button
                            title="Cancel"
                            onPress={
                                () => {
                                    doneEditing()
                                    clearUserTestsDetails()
                                }
                            }
                            color="rgb(255, 0, 80)"
                        />
                        {!!Object.keys(allTestCases).length ? (
                            <>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text 
                                        style={{
                                            flex: 4,
                                        }}
                                        h4
                                    >
                                        Select Test Cases
                                    </Text>
                                    {!showTestSuites ? (
                                        <Button 
                                            title="Test Suites"
                                            style={{
                                                flex: 2,
                                            }}
                                            onPress={toggleTestSuites}
                                        />
                                    ) : (
                                        <Button 
                                            title="Test Cases"
                                            style={{
                                                flex: 2,
                                            }}
                                            onPress={toggleTestSuites}
                                        />
                                    )}
                                </View>
                                {!showTestSuites ? (
                                    <Input 
                                        placeholder="Search for Test Cases to Add"
                                        onChangeText={search}
                                        rightIcon={{ type: "font-awesome", name: "search" }}
                                    />
                                ) : (
                                    <View>
                                        {Object.entries(allTestSuites)?.map(
                                            ([suiteID, suite]) => (
                                                <View
                                                    key={suiteID}
                                                    style={{
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            flex: 4,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                        {suite.description}
                                                    </Text>
                                                    <Button 
                                                        title="Add Tests From Suite"
                                                        onPress={
                                                            ()=> {
                                                                const selected = { ...selectedTestCaseMap }
                                                                suite.testCases.forEach(
                                                                    testCaseID => {
                                                                        selected[testCaseID] = true
                                                                    }
                                                                )
                                                                setSelectedTestCaseMap(selected)
                                                            }
                                                        }
                                                        style={{
                                                            flex: 2,
                                                        }}
                                                    />
                                                </View>
                                            )
                                        )}
                                    </View>
                                )}
                                
                            </>
                        ) : <Text>No Test Cases...</Text>}
                        {!showTestSuites && !!Object.keys(testCases).length && Object.entries(testCases).map(
                            ([testCaseID, testCase]) => (
                                <View
                                    key={testCaseID}
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <CheckBox
                                        left
                                        checked={selectedTestCaseMap[testCaseID]}
                                        onPress={()=>handleTestCaseSelection(testCaseID)}
                                    />
                                    <Text>
                                        {testCase.description}
                                    </Text>
                                </View>
                            )
                        )}
                    </View>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
        </View>
    )
}

export default UserTestsEditor