import React from "react"
import {
    View,
} from "react-native"
import styles from "../Stylesheets/Global"

const DGHR = (props) => {
    const {
        style,
        horizontal=true,
    } = props
    return (
        <View 
            style={{
                ...styles.DGHR,
                ...style,
            }} 
        />
    )
}

export default DGHR