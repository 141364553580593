import uuid from "react-native-uuid"

import DGViewModel from "./DGViewModel"

export type FirebaseImage = {
    imageURL: string
    fullImagePath: string
    description?: string
    imageURLError?: string
    fullImagePathError?: string
}

export type TestCase = {
    id: string
    description: string
    expectedBehavior: string
    image?: FirebaseImage
    createdAt: string
    updatedAt: string
    descriptionError?: string
    expectedBehaviorError?: string
    imageError?: string
}

export type Test = {
    case: TestCase
    pass?: boolean
    image?: FirebaseImage
    actualBehavior?: string  
    actualBehaviorError?: string
    testCaseError?: string
    imageError?: string
}

export type TestSuite = {
    id: string
    description: string
    testCases: string[]
    createdAt: string
    updatedAt: string
    descriptionError?: string
    testCasesError?: string
}

export type TestSuiteGroup = {
    id: string
    description: string
    testSuites: {[key: string]: TestSuite}
    createdAt: string
    updatedAt: string
    descriptionError?: string
    testSuitesError?: string
}

export type UserTests = {
    id: string
    softwareName: string
    tests: {[key: string]: Test}
    createdAt: string
    updatedAt: string
    testsError?: string
    softwareNameError?: string
}

class TestingViewModel extends DGViewModel {

    constructor(firestore: any, requestPermission: () => void, status: any) {
        super(firestore, requestPermission, status)
    }

    newID = (): string => {
        return String(uuid.v4())
    }

    loadTestCases = async (): Promise<TestCase[]> => {
        const testCases: TestCase[] = await this.db?.read("TestCase", this.db?.user?.uid)

        return testCases
    }

    loadTestSuites = async (): Promise<TestSuite[]> => {
        const testSuites: TestSuite[] = await this.db?.read("TestSuite", this.db?.user?.uid)

        return testSuites
    }

    loadTestSuiteGroups = async (): Promise<TestSuiteGroup[]> => {
        const testSuiteGroups: TestSuiteGroup[] = await this.db?.read("TestSuiteGroup", this.db?.user?.uid)

        return testSuiteGroups
    }

    loadUserTests = async (): Promise<UserTests> => {
        const userTests: UserTests = await this.db?.read("UserTests", this.db?.user?.uid)

        return userTests
    }

    loadAdminData = async (): Promise<{userTests: [], testCases: []}> => {
        const userTests = await this.db.getAllDocuments("UserTests")
        const testCases = await this.db.getAllDocuments("TestCase")
        return {
            userTests,
            testCases,
        }
    }

    saveTestCase = async (testCase: TestCase): Promise<TestCase> => {
        testCase.id = testCase.id ?? this.newID()
        if (TestingViewModel.validateTestCase(testCase)) {
            await this.db?.update("TestCase", this.db?.user?.uid, {
                [testCase.id]: testCase
            })            
        }

        return testCase
    }

    saveTestSuite = async (testSuite: TestSuite): Promise<TestSuite> => {
        testSuite.id = testSuite.id ?? this.newID()
        if (TestingViewModel.validateTestSuite(testSuite)) {
            await this.db?.update("TestSuite", this.db?.user?.uid, {
                [testSuite.id]: testSuite
            })
        }
        return testSuite
    }

    saveTestSuiteGroup = async (testSuiteGroup: TestSuiteGroup): Promise<TestSuiteGroup> => {
        testSuiteGroup.id = testSuiteGroup.id ?? this.newID()
        if (TestingViewModel.validateTestSuiteGroup(testSuiteGroup)) {
            await this.db?.update("TestSuiteGroup", this.db?.user?.uid, {
                [testSuiteGroup.id]: testSuiteGroup
            })
        }
        return testSuiteGroup
    }

    saveUserTests = async (userTests: UserTests): Promise<UserTests> => {
        userTests.id = userTests.id ?? this.newID()
        if (TestingViewModel.validateUserTests(userTests)) {
            await this.db?.update("UserTests", this.db?.user?.uid, {
                [userTests.id]: userTests,
            })
        }
        return userTests
    }

    removeTestImage = async (userTestsID: string, test: Test) => {
        await this.db.deleteImage(test.image.fullImagePath)
        await this.db.deleteDocField("UserTests", this.db.user.uid, `${userTestsID}.tests.${test.case.id}.image`)
    }

    passTest = async (userTestsID: string, test: Test) => {
        if (test?.image?.fullImagePath) {
            this.removeTestImage(userTestsID, test)
        }

        await this.db.update("UserTests", this.db.user.uid, {
            [`${userTestsID}.tests.${test.case.id}.pass`]: true,
            [`${userTestsID}.tests.${test.case.id}.actualBehavior`]: "",
        })
    }

    unPassTest = async (userTestsID: string, test: Test) => {
        await this.db.deleteDocField("UserTests", this.db.user.uid, `${userTestsID}.tests.${test.case.id}.pass`)
    }

    saveTest = async (userTestsID: string, test: Test) => {
        await this.db.update("UserTests", this.db.user.uid, {
            [`${userTestsID}.tests.${test.case.id}`]: test
        })
    }

    deleteTest = async (userTestsID: string, test: Test) => {
        await this.db.deleteDocField("UserTests", this.db.user.uid, `${userTestsID}.tests.${test.case.id}`)
    }

    getTestsAsCSV = (userTests: UserTests): string[][] => {
        const tests = Object.values(userTests?.tests || [])
        const csv: string[][] = [["Description", "Expected Behavior", "Actual Behavior", "Pass", "ImageURL"]]
        tests.forEach(test => {
            let pass = "N/A"
            if (typeof test.pass === "boolean")
                pass = test.pass ? "Pass" : "Fail"

            // remove the new line from the expected behavior and actual behavior
            test.case.expectedBehavior = test.case.expectedBehavior?.replace(/"/g, "'")
            test.actualBehavior = test.actualBehavior?.replace(/"/g, "'")
            csv.push([
                test.case.description,
                test.case.expectedBehavior,
                test.actualBehavior ?? "",
                pass,
                test.image?.imageURL ?? "",
            ])
        })

        return csv
    }

    static validateTestCase = (testCase: TestCase): boolean => {
        let isValidTestCase = true
        
        if (testCase.expectedBehavior?.length > 2000) {
            testCase.expectedBehaviorError = "Expected behavior must be fewer than 2000 characters"
            isValidTestCase = false
        }
        if (testCase.image && !TestingViewModel.validateFirebaseImage(testCase.image)) {
            testCase.imageError = "Invalid expected behavior image"
            isValidTestCase = false
        }

        return isValidTestCase
    }

    static validateTestSuite = (testSuite: TestSuite): boolean => {
        if (testSuite.description.length < 3 || testSuite.description.length > 30) {
            testSuite.descriptionError = "Description must be between 3 and 30 characters"
            return false
        }
        
        return true
    }

    static validateTestSuiteGroup = (testSuiteGroup: TestSuiteGroup): boolean => {
        if (testSuiteGroup.description.length < 3 || testSuiteGroup.description.length > 30) {
            testSuiteGroup.descriptionError = "Description must be between 3 and 30 characters"
            return false
        }
        
        return true
    }

    static validateTest = (test: Test): boolean => {
        let isValidTest = true

        if (!this.validateTestCase(test.case)) {
            test.testCaseError = "Invalid test case for test"
            isValidTest = false
        }
        if (!!test.actualBehavior?.length && test.actualBehavior?.length > 2000) {
            test.actualBehaviorError = "Actual behavior must be fewer than 2000 characters"
            isValidTest = false
        }
        if (test.image && !TestingViewModel.validateFirebaseImage(test.image)) {
            test.imageError = "Invalid actual behavior image"
            isValidTest = false
        }
        return isValidTest
    }

    static validateUserTests = (userTests: UserTests): boolean => {
        let isValidUserTests = true

        if (userTests.softwareName.length < 3 || userTests.softwareName.length > 30) {
            userTests.softwareNameError = "Software Name must be between 3 and 30 characters"
            isValidUserTests = false
        }
        if(!Object.keys(userTests.tests)?.length) {
            userTests.testsError = "You must add at least one test case"
            isValidUserTests = false
        }

        Object.values(userTests?.tests)?.forEach(
            test => {
                if (!this.validateTest(test)) {
                    userTests.testsError = "Invalid tests for user"
                    isValidUserTests = false
                }
            }
        )

        return isValidUserTests
    }

    static validateFirebaseImage = (firebaseImage: FirebaseImage): boolean => {
        let isValidImageData = true

        if (!firebaseImage?.fullImagePath) {
            firebaseImage.fullImagePathError = "Invalid image name"
            isValidImageData = false
        }
        if (!firebaseImage?.imageURL) {
            firebaseImage.imageURLError = "Invalid image url"
            isValidImageData = false
        }

        return isValidImageData
    }
}

export default TestingViewModel