import React, {
    useState,
    useRef
} from "react"
  
import {
    View,
    TextInput,
    ScrollView,
    SafeAreaView,
    KeyboardAvoidingView,
    ActivityIndicator,
    TouchableWithoutFeedback,
    Image,
    Keyboard,
    Platform
} from "react-native"

import {
    Text,
    Button,
} from "react-native-elements"
  
import styles from "./Stylesheets/Auth"
import logo from "../assets/dgClear.png"
  
const SignInScreen = (props) => {
  
    const {
        firestore,
        navigation
    } = props
  
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
  
    const passwordRef = useRef()
  
    const handleKeyboardDismiss = () => {
        if (Platform.OS !== "web")
            Keyboard.dismiss()
    }
  
    const handlePasswordReset = async () => {
        setLoading(true)
        const _ = await firestore.handlePasswordReset(email)
        alert("If an account exists with the email address you entered, you will receive an email to reset your password.")
        setLoading(false)
        navigation.navigate("Log In")
    }

    return (     
        <KeyboardAvoidingView 
            behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
            <TouchableWithoutFeedback onPress={handleKeyboardDismiss}>
                <SafeAreaView style={styles.container}>
                    <ScrollView style={styles.form}>
                        <Image source={logo} style={styles.logo}/>
                        <Text h3 style={styles.header}>Login Help</Text>
                        {loading &&
                        <ActivityIndicator size={"large"} />
                        }
                        <Text
                            style={styles.error}
                        >
                            {error}
                        </Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Email"
                            placeholderTextColor="#B1B1B1"
                            returnKeyType="next"
                            keyboardType="email-address"
                            textContentType="emailAddress"
                            value={email}
                            onChangeText={setEmail}
                            onSubmitEditing={() => passwordRef.current.focus()}
                        />
                        <Button
                            onPress={handlePasswordReset}
                            title="Send Reset Link"
                        />
                        <Text
                            style={styles.helpLink}
                            onPress={() => {
                                navigation.navigate("Log In")
                            }}
                        >
                            Back to login
                        </Text>
                    </ScrollView>
                </SafeAreaView>
            </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
    )
}
  
export default SignInScreen