import React, {
    useEffect,
    useState,
    useRef,
} from "react"

import { 
    ActivityIndicator,
    View,
    TouchableOpacity,
    Platform,
    ScrollView,
} from "react-native"

import {
    Text,
    Divider,
} from "react-native-elements"

import {
    Picker
} from "@react-native-picker/picker"

import TestingViewModel, {
    TestCase,
    FirebaseImage,
} from "../ViewModel/TestingVM"

import styles from "./Stylesheets/Global"
import DGTestCaseRow from "./Component/DGTestCaseRow"
import TestCaseEditor from "./TestCaseEditor"
import TestSuiteEditor from "./TestSuiteEditor"

import Icon from "react-native-vector-icons/FontAwesome5"
import * as ImagePicker from "expo-image-picker"

let allTestCases = {}
let allTestSuites = {}

const TestCases = (props) => {

    const {
        firestore,
        navigation,
        initialScrolltop=0,
    } = props

    const [status, requestPermission] = ImagePicker.useCameraPermissions()
    const [testCases, setTestCases] = useState({})
    const [testSuites, setTestSuites] = useState({})
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showTestCaseModal, setShowTestCaseModal] = useState(false)
    const [showTestSuiteModal, setShowTestSuiteModal] = useState(false)
    const [image, setImage] = useState(null)
    const [VM, setVM] = useState(undefined)
    const [progress, setProgress] = useState(0)
    const [editingTestCase, setEditingTestCase] = useState(undefined)
    const [editingTestSuite, setEditingTestSuite] = useState(undefined)
    const [sortFilter, setSortFilter] = useState("description")
    const [selectedTestSuite, setSelectedTestSuite] = useState("all")
    const [scrolltop, setScrolltop] = useState(initialScrolltop)

    const sortTestCases = (a, b) => {
        if(a[sortFilter] < b[sortFilter])
            return -1
        else if(a[sortFilter] > b[sortFilter])
            return 1

        return 0
    }

    const sortTableData = (tC) => {
        const tD = Object.values(tC)
        tD.sort(sortTestCases)
        setTableData(tD)
    }

    const loadData = async () => {
        const testingVM = new TestingViewModel(firestore, requestPermission, status)
        setLoading(true)
        setVM(testingVM)
        const tC = await testingVM.loadTestCases()
        const tS = await testingVM.loadTestSuites()
        setLoading(false)
        sortTableData(tC)
        setTestCases(tC)
        allTestCases = tC
        allTestSuites = tS
        setTestSuites(tS)    
        setScrollViewY(scrolltop)
    }

    const addTestCase = async (newTestCase: TestCase, shouldUploadImage: boolean) => {
        const callback = (res) => saveTestCase({
            newTestCase, 
            ...res,
        })
        if (image && shouldUploadImage)
            firestore?.uploadImage(image, callback, imageUploadProgressUpdate)
        else
            saveTestCase({
                newTestCase
            })
    }

    const setScrollViewY = (y) => {
        myscroll?.current && myscroll.current?.scrollTo({x: 0, y: y, animated: false})
    }

    const addTestSuite = async (newTestSuite) => {
        setLoading(true)
        await doneEditing(false)
        await VM.saveTestSuite(newTestSuite)
        await loadData()
        selectTestSuite(newTestSuite.id)
    }

    const clearTestCaseDetails = () => {
        setImage(null)
    }

    const clearTestSuiteDetails = () => {
        setImage(null)
        // setEditingTestSuite(undefined)
    }

    const saveTestCase = async (data) => {
        const {
            newTestCase,
            downloadURL,
            imagePath,
        } = data

        if(imagePath) {
            const img: FirebaseImage = {
                imageURL: downloadURL,
                fullImagePath: imagePath,
            }
            newTestCase.image = img
        }
        setScrollViewY(scrolltop)
        setLoading(true)
        await doneEditing(false)
        await VM.saveTestCase(newTestCase)
        await loadData()
    }

    const imageUploadProgressUpdate = (p) => {
        setProgress(p)
    }

    const doneEditing = async (reload=true) => {
        clearTestCaseDetails()
        clearTestSuiteDetails()
        setShowTestCaseModal(false)
        setShowTestSuiteModal(false)
        if (reload)
            await loadData()
            
        selectTestSuite(selectedTestSuite)
    }

    const openTests = () => {
        navigation.navigate("Tests")
    }

    const selectTestSuite = (testSuiteID) => {
        setSelectedTestSuite(testSuiteID)
        let newTC = {}
        if (testSuiteID === "all") {
            newTC = { ...allTestCases }
            setEditingTestSuite(undefined)
        } else {
            setEditingTestSuite(allTestSuites[testSuiteID])
            Object.entries(allTestCases).forEach(
                ([tID, t]) => {
                    if (allTestSuites[testSuiteID]?.testCases?.includes(tID)) 
                        newTC[tID] = t
                }
            )
        }
        setTestCases(newTC)
        sortTableData(newTC)
    }

    useEffect(
        () => {
            loadData()
        }, [firestore]
    )

    
    const myscroll = useRef(null)
    return (
        <>
            {!showTestCaseModal && !showTestSuiteModal && (
                <ScrollView 
                    style={styles.container} 
                    ref={myscroll}
                    onScroll={(event) => setScrolltop(event?.nativeEvent?.contentOffset?.y)}
                    scrollEventThrottle={3}
                >
                    <View 
                        style={styles.tableViewHeader}
                    >
                        <Text h3 style={styles.header}>Test Cases</Text>
                        <Icon 
                            name="plus-square" 
                            size={30} 
                            color="black"
                            onPress={()=>setShowTestCaseModal(true)}
                        />
                    </View>
                    <Divider />
                    <View
                        style={{
                            flexDirection: "row",
                            paddingTop: 10,
                            paddingHorizontal: 0,
                            flex: 1,
                        }}
                    >
                        <View
                            style={{
                                flex: 4,
                                alignItems: "flex-start",
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                {Platform.OS === "web" && (
                                    <View style={{ marginLeft: 8 }} />
                                )}
                                <Picker
                                    selectedValue={selectedTestSuite}
                                    itemStyle={{
                                        height: 45,
                                    }}
                                    style={styles.dgPickerStyle}
                                    onValueChange={
                                        (itemValue, itemIndex) => {
                                            selectTestSuite(itemValue)
                                        }
                                    }
                                >
                                    <Picker.Item label="All Test Cases" value="all" />
                                    {!!Object.keys(testSuites).length && Object.entries(testSuites).map(
                                        ([testSuiteID, testSuite]) => (
                                            <Picker.Item
                                                label={testSuite.description}
                                                value={testSuiteID}
                                                key={testSuiteID}
                                            />
                                        )
                                    )}
                                </Picker>
                                <TouchableOpacity
                                    style={{
                                        paddingHorizontal: 5,
                                    }}
                                    onPress={()=>(setShowTestSuiteModal(!showTestSuiteModal))}

                                >
                                    <Icon 
                                        name="folder-plus" 
                                        size={20} 
                                        color="black"
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View
                            style={{
                                flex: 2, 
                                alignItems: "flex-end",
                                marginRight: 5,
                            }}
                        >
                            <TouchableOpacity
                                onPress={openTests}
                                style={[styles.dgButton]}
                            >
                                <Text
                                    style={styles.dgButtonText}
                                >
                                    Tests
                                </Text>
                                <Icon 
                                    name="flask" 
                                    size={20} 
                                    color="rgb(255,50,50)"
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                    {!loading && !tableData.length && (
                        <View>
                            <Text h4 style={{ textAlign: "center", padding: 20 }}>
                                You have not added any test cases.
                            </Text>
                            <Text h5 style={{ textAlign: "center", padding: 20 }}>
                                It would be a lot cooler if you did...
                            </Text>
                        </View>
                    )}
                    {loading ? <ActivityIndicator style={styles.loading} size="large" /> : (
                        !!tableData?.length && tableData.map(
                            testCase => (
                                <DGTestCaseRow 
                                    key={testCase.id}
                                    testCase={testCase}
                                    headerButtons={[{
                                        name: "edit",
                                        action: (testCase) => {
                                            setImage(testCase?.image?.imageURL)
                                            setEditingTestCase(testCase)
                                            setShowTestCaseModal(true)
                                        },
                                        size: 25,
                                    }]}
                                />
                            )
                        )
                    )}
                    <View
                        style={{
                            marginBottom: 120,
                        }}
                    />
                </ScrollView>
            )}
            {showTestCaseModal && (
                <ScrollView>
                    <TestCaseEditor 
                        image={image}
                        setImage={setImage}
                        doneEditing={doneEditing}
                        addTestCase={addTestCase}
                        progress={progress}
                        testCase={editingTestCase}
                        firestore={firestore}
                        testingVM={VM}
                    />
                </ScrollView>
            )}
            {showTestSuiteModal && (
                <ScrollView>
                    <TestSuiteEditor
                        doneEditing={doneEditing}
                        addTestSuite={addTestSuite}
                        testSuite={editingTestSuite}
                        firestore={firestore}
                        allTestCases={allTestCases}
                    />
                </ScrollView>
            )}
        </>
    )
}

export default TestCases