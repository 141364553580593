import React, { 
    useState, 
    useEffect,
    createContext,
} from "react"

import uuid from "react-native-uuid"

import { 
    initializeApp 
} from "firebase/app"

import { 
    EmailAuthProvider,
    getAuth, 
    signInWithEmailAndPassword, 
    sendPasswordResetEmail, 
    createUserWithEmailAndPassword,
    signOut,
    updateProfile,
    updateEmail,
    updatePassword,
    reauthenticateWithCredential
} from "firebase/auth"

import {
    addDoc,
    getFirestore, 
    collection,
    doc, 
    getDoc,
    getDocs,
    query,
    setDoc,
    deleteDoc,
    deleteField,
    updateDoc,
    where
} from "firebase/firestore/lite"

import {
    getStorage,
    ref,
    uploadBytesResumable,
    uploadBytes,
    getDownloadURL,
    deleteObject,
} from "firebase/storage"

export const Context = createContext()

const{
    Provider,
    Consumer
} = Context

import firebaseConfig from "./FirebaseConfig"

const app = initializeApp(firebaseConfig)
const firestore = getFirestore(app)
const storage = getStorage()
const auth = getAuth(app)

const FirebaseContextProvider = (props) => {
    const {
        children,
    } = props
    const [user, setUser] = useState(undefined)
    const [progress, setProgress] = useState(undefined)
    const handleUserAuthStateChange = (u) => {
        setUser(u)
    }

    useEffect(()=> {
        const listener = auth.onAuthStateChanged(handleUserAuthStateChange)
        return function cleanup() {
            listener && listener()
        }
    }
    , [firestore])

    const initializeDocument = async (collectionName, documentName) => {
        let document = undefined
        let docRef = doc(firestore, collectionName, documentName)
        await getDoc(docRef).then(
            docSnap => {
                if (docSnap.exists()) {
                    document = docSnap
                } else {
                    // doc.data() will be undefined in this case
                    docRef = undefined
                    // console.error(`The collection ${collectionName} could not be loaded from firestore.`)
                }
            }
        ).catch()

        return { document, docRef }
    }

    const create = async (collectionName, documentName, documentData) => {
        let newDocument
        if (documentName) {
            newDocument = doc(firestore, collectionName, documentName)
            await setDoc(newDocument, documentData, {
                merge: true
            }) 
        }
        else {
            newDocument = await addDoc(
                collection(firestore, collectionName), 
                documentData
            )
        }

        return newDocument
    }

    const read = async (collectionName, documentName) => {
        const {
            document, 
            docRef
        } = await initializeDocument(collectionName, documentName)

        return (document && document.data()) ?? {}
    }

    const update = async (collectionName, documentName, updateData) => {
        const {
            document, 
            docRef
        } = await initializeDocument(collectionName, documentName)

        if (document) {
            updateDoc(docRef, updateData)
        } else {
            await create(collectionName, documentName, updateData)
        }
    }

    const search = async (collectionName, field, needle, comparison="==", caseSensitive=false) => {
        let haystack
        
        let result = []

        try {
            haystack = collection(firestore, collectionName)
        }
        catch (error) {
            return false
        }
        const q = query(haystack, where(field, comparison, caseSensitive ? needle : needle.toLowerCase()))
        
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            let r = doc.data()
            r.documentID = doc.id
            result.push(r)
        })
        return result
    }

    const getAllDocuments = async (collectionName) => {
        let docs = []
        const querySnapshot = await getDocs(collection(firestore, collectionName))
        querySnapshot.forEach(doc => {
            const data = doc.data()
            Object.keys(data).forEach(
                d => {
                    data[d].documentID = doc.id
                }
            )
            docs.push(data)
        })

        return docs
    }

    const deleteDocument = async (collectionName, documentName) => {
        const {
            document, 
            docRef
        } = await initializeDocument(collectionName, documentName)
        if (document) {
            deleteDoc(docRef)
        }
    }

    const deleteDocField = async (collectionName, documentName, field) => {
        const {
            document, 
            docRef
        } = await initializeDocument(collectionName, documentName)

        const deletionRequest = {}
        deletionRequest[field] = deleteField()

        if (document) {
            updateDoc(docRef, deletionRequest)
        }
    }

    const updateUserAccount = async (email, oldPassword, newPassword, firstName, lastName) => {
        if (!user) {
            console.error("The user is not authenticated.")
            return false
        }

        let userUpdateData = {}
        if (firstName) {
            await updateProfile(auth.currentUser, {
                displayName: firstName
            })
            userUpdateData.firstName = firstName
        }
        if (lastName)
            userUpdateData.lastName = lastName

        if (email) {
            try {
                await updateEmail(auth.currentUser, email)
            } catch (error) {
                alert("The email address you provided was invalid.")
                return
            }
        }
        if (email || newPassword) {
            if (email && !oldPassword) {
                alert("You must enter your password to change the email address")
                return
            }
            if (newPassword && !oldPassword) {
                alert("You must enter your old password to change the password")
                return
            }
            const credential = EmailAuthProvider.credential(
                user.email,
                oldPassword
            )
            const result = await reauthenticateWithCredential(
                auth.currentUser, 
                credential
            )
            if (!result.user) {
                alert("Invalid credentials. Please try again.")
                return
            }
        }
        if (newPassword) {
            try {
                
                await updatePassword(auth.currentUser, newPassword)
            } catch (error) {
                alert("The new password you provided was invalid.")
                return
            }
        }
        if (Object.keys(userUpdateData).length > 0)
            await update("User", auth.currentUser.uid, userUpdateData)
        
        alert("Your account was updated successfully")
    }

    const deleteAccount = async (password) => {
        let result
        try {
            const credential = EmailAuthProvider.credential(
                user.email,
                password
            )
            result = await reauthenticateWithCredential(
                auth.currentUser, 
                credential
            )
        } catch (error) {
            alert("Invalid credentials. Please try again.")
            return
        }
        
        if (!result.user) {
            alert("Invalid credentials. Please try again.")
            return
        }
        await deleteDocument("TimeFriend", auth.currentUser.uid)
        await deleteDocument("DailyBreakdown", auth.currentUser.uid)
        await deleteDocument("User", auth.currentUser.uid)
        const userCategories = await search("Category", "userCreated", auth.currentUser.uid, "==", true)
        
        for (const customCategory of userCategories) {
            await deleteDocument("Category", customCategory.documentID)
        }
        
        await auth.currentUser.delete()
        alert("Your account and data have been deleted successfully.")
    }

    const signUpWithEmail = async (email, password, firstName, lastName) => {
        let signUpError = ""
        const resp = await createUserWithEmailAndPassword(auth, email, password)
            .catch(error => {
                let errorCode = error.code
                let errorMessage = error.message
                if (errorCode == "auth/weak-password") {
                    signUpError = "Your password is not strong enough. Please be sure to use a minumum of 6 characters."
                } else {
                    signUpError = "Could not create an account with the information given"
                }
            })
        if (signUpError !== "")
            return signUpError
        else {
            try {
                // Could add email verification here
                // const auth = getAuth()
                // sendEmailVerification(auth.currentUser)
                const currentUser = auth.currentUser
                await create("User", currentUser.uid, { firstName, lastName })
                await updateProfile(currentUser, {
                    displayName: firstName
                })
            } catch(e) {
                console.error(e)
                signUpError = "There was an issue saving the new user's data. Please check the information and try again."
            }
            
        }
        
        return signUpError
    }

    const signInWithEmail = async (email, password) => {
        let signInError = undefined
        await signInWithEmailAndPassword(auth, email, password)
            .catch(error => {
                let errorMessage = error.message
                signInError = errorMessage
            })

        if (signInError) {
            return "Your login credentials could not be verified"
        }
        else {
            return
        }
    }

    /**
     * Upload an image to the firebase storage bucket
     *
     * @param {string} imageFileURL the url of the local image to be uploaded
     * @param {Function} onUploadComplete callback to set the download url
     * @param {Function} setProgress callback to update the progress bar
     * @param {boolean} detailedProgress use uploadBytesResumable to track detailed progress
     * @returns {*} download url, image path on success, false otherwise
     */
    const uploadImage = async (imageFileURL, onUploadComplete, setProgress, detailedProgress=false) => {
        const img = await fetch(imageFileURL)
        const blob = await img.blob()
        const imagePath = uuid.v4()

        if (blob) {
            if (!detailedProgress) {
                const imageRef = ref(storage, imagePath)
                setProgress(1)
                uploadBytes(imageRef, blob).then(
                    snapshot => {
                        getDownloadURL(snapshot.ref).then((downloadURL) => {
                            setProgress(100)
                            onUploadComplete({ downloadURL, imagePath })
                        })
                    }
                )
            } else {
                const uploadTask = uploadBytesResumable(imageRef, blob)
                uploadTask.on("state-changed",
                    (snapshot) => {
                        setProgress(
                            snapshot.bytesTransferred * 100 / snapshot.totalBytes
                        )
                    },
                    (e) => {
                        console.error(e)
                    },
                    () => {
                        /**/
                    },
                )
            }
        }

        return false
    }

    /**
     * Delete an image from the storage bucket
     *
     * @param {string} imageName
     * @returns {boolean} true if delete was successful, else false
     */

    const deleteImage = async (imagePath) => {
    // Create a reference to the file to delete
        if (!imagePath) {
            return false
        }
        const imageRef = ref(storage, imagePath)

        // Delete the file
        await deleteObject(imageRef)
        return true
    }

    const signOutUser = () => {
        signOut(auth)
    }

    const handlePasswordReset = async (email) => {
        let success = true
        await sendPasswordResetEmail(auth, email)
            .then(console.log("Password reset email sent successfully"))
            .catch(
                err => success = false
            )
        return success
    }

    return <Provider value={{ 
        firestore: {
            create, 
            read, 
            update,
            deleteAccount,
            deleteDocument, 
            deleteDocField,
            search,
            setProgress,
            signUpWithEmail, 
            signInWithEmail,
            signOutUser,
            getDocs,
            getAllDocuments,
            handlePasswordReset,
            updateUserAccount,
            user,
            uploadImage,
            deleteImage,
        }, progress
    }
    }>
        {children}
    </Provider>
}

export { FirebaseContextProvider }

export default Consumer