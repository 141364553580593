// import { 
//     DGAPIRequestModule, 
//     DGAPIResponseParser 
// } from "@datagee/rest-api-tools"

const MAX_FILE_SIZE = 10 * 1024 * 1024

const TEST_LOCAL = false

if (TEST_LOCAL)
    console.warn("Local testing mode enabled")

const DATAGEE_API_URL = TEST_LOCAL ? "http://localhost:3000" : "https://api.datagee.com"

import * as ImagePicker from "expo-image-picker"

class DGViewModel {
    db?: any
    requestPermission?: Function
    status?: ImagePicker.CameraPermissionResponse

    constructor(db?: any, requestPermission?: Function, status?: any) {
        this.db = db
        this.requestPermission = requestPermission
        this.status = status
    }

    async makeAPICall(data: object, path="", method="POST", url=DATAGEE_API_URL) {
        const token = await this.db.user.getIdToken()
        const params = {
            idToken: token,
            data: JSON.stringify(data),
            type: "application/x-www-form-urlencoded"
        }
        try {
            const requestData = {
                method: method,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                }, 
                body: this.constructFormData(params),
            }
            const apiResponse = await fetch(`${url}/${path}`, requestData)
            const response = await apiResponse.json()
            if(!response){
                console.error("Response Failed!")
                return undefined
            }
            return response
        } catch (error) {
            console.error(error)
        }
    }
    
    // Inspired By: https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    constructFormData = (details: object): string => {
        const formBody = []
        for (const property in details) {
            const encodedKey = encodeURIComponent(property)
            const encodedValue = encodeURIComponent(details[property])
            formBody.push(encodedKey + "=" + encodedValue)
        }

        return formBody.join("&")
    }

    pickImage = async (setImage: Function) => {
        // No permissions request is necessary for launching the image library
        const result: any = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 0,
        })
        
        if (!result.cancelled) {
            setImage(result.uri)
        }
    }

    takePhoto = async (setImage: Function) => {
        if(!this.status?.granted && typeof this.requestPermission === "function")
            await this.requestPermission()
        const result: any = await ImagePicker.launchCameraAsync()
        
        if (!result.cancelled) {
            setImage(result.uri)
        }
    }
}

export default DGViewModel