import React, {
    useState,
} from "react"
import {
    View,
    Text,
    Image,
    ImageBackground,
    Button,
    TouchableOpacity,
    Modal,
    Dimensions,
} from "react-native"
import DGHR from "./DGHR"
import styles from "../Stylesheets/Global"
import Icon from "react-native-vector-icons/FontAwesome5"

const DGTestCaseRow = (props) => {
    const {
        description,
        expectedBehavior,
        id,
        style,
        tableRowHeadingStyle,
        tableRowHeadingDescriptionStyle,
        tableRowHeadingActionStyle,
        tableRowImageStyle,
    } = props?.testCase

    const {
        test
    } = props

    const [showDetails, setShowDetails] = useState(false)
    const [fullScreenImageURI, setFullScreenImageURI] = useState("")
    const [fullScreenImageRatio, setFullScreenImageRatio] = useState(1)
    const { width, height } = Dimensions.get("window")

    const icons = props?.headerButtons
    const noAction = () => console.warn("Action icon has no associated action.")
    const performIconAction = (icon) => {
        if (typeof icon.action === "function") {
            icon.action(props?.testCase, props?.test)
        } else
            noAction()
    }

    const expandImage = (imageURL) => {
        Image.getSize(imageURL, 
            (width, height) => {
                setFullScreenImageRatio(height / width)
            }
        )

        setFullScreenImageURI(imageURL)
    }

    return (
        <>
            <View 
                style={[
                    style, 
                    styles.tableRow,
                ]}
                key={`test-case-${id}`}
            >
                <View
                    style={[
                        styles.tableRowHeading,
                        tableRowHeadingStyle, 
                    ]}
                >
                 
                    <Text
                        style={[
                            styles.tableRowHeadingDescription,
                            tableRowHeadingDescriptionStyle,
                        ]}
                    >
                        {description}
                    </Text>
                    <View
                        style={[
                            styles.tableRowHeadingAction,
                            tableRowHeadingActionStyle,
                        ]}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                flex: 3,
                            }}
                        >
                            {!!icons?.length && icons.map(
                                icon => (
                                    <Icon 
                                        name={icon.name || "edit"}
                                        onPress={()=>performIconAction(icon)}
                                        size={icon.size || 12}
                                        key={icon.name}
                                        color={icon.color ?? "black"}
                                        style={{
                                            marginLeft: 20,
                                        }}
                                    />
                                )
                            )}
                        </View>
                    </View>
                </View>
                <TouchableOpacity
                    onPress={() => setShowDetails(!showDetails)}
                >
                    <View
                        style={styles.tableRowDetails}
                    >
                        <Text>
                            Expected Behavior: {expectedBehavior}
                        </Text>
                    </View>
                    {(props?.testCase?.image || props?.test?.image) && (
                        <Icon 
                            name={showDetails ? "caret-up" : "caret-down"}
                            size={20}
                            color="black"
                            style={{
                                alignSelf: "center",
                            }}
                        />
                    )}
                    {showDetails && props?.testCase?.image && (
                        <TouchableOpacity
                            onPress={()=>expandImage(props.testCase.image.imageURL)}
                        >
                            <Text 
                                style={{
                                    textAlign:"center", 
                                    padding: 8, 
                                    fontWeight: "bold",
                                }}
                            >
                                Expectations
                            </Text>
                            <Image 
                                source={{
                                    uri: props?.testCase?.image.imageURL,
                                }}
                                style={[
                                    styles.tableRowImage,
                                    tableRowImageStyle,
                                ]}
                            />
                        </TouchableOpacity>
                    )}
                    {showDetails && test?.image && (
                        <TouchableOpacity>
                            <Text 
                                style={{
                                    textAlign:"center", 
                                    padding: 8, 
                                    fontWeight: "bold",
                                }}
                            >
                                Results
                            </Text>
                            <Image 
                                source={{
                                    uri: test?.image.imageURL,
                                }}
                                style={[
                                    styles.tableRowImage,
                                    tableRowImageStyle,
                                ]}
                            />
                        </TouchableOpacity>
                    )}
                    {showDetails && test?.actualBehavior && (
                        <Text 
                            style={{
                                padding: 10,
                            }}
                        >
                            Actual Behavior: {test.actualBehavior}
                        </Text>
                    )}
                </TouchableOpacity>
                <DGHR />
            </View>
            <Modal
                visible={!!fullScreenImageURI}
            >
                <View
                    style={{
                        paddingTop: 60,
                        paddingRight: 30,
                        paddingBottom: 20,
                        alignItems: "flex-end",
                    }}
                >
                  
                    <Icon 
                        name="times" 
                        size={30} 
                        color="black"
                        onPress={
                            () => {
                                setFullScreenImageURI("")
                            }
                        }
                    />
                </View>
                <Image
                    source={{
                        uri: fullScreenImageURI,
                    }}
                    resizeMode="cover"
                    style={{
                        width: "100%",
                        height: width * fullScreenImageRatio,
                    }}
                />
            </Modal>
        </>
    )
}

export default DGTestCaseRow