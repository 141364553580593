import Icon from "react-native-vector-icons/FontAwesome5"
import * as ImagePicker from "expo-image-picker"

import React, {
    useEffect,
    useState,
    useRef,
} from "react"

import { 
    ActivityIndicator,
    View,
    TouchableOpacity,
    Platform,
    ScrollView,
} from "react-native"

import {
    Text,
    Divider,
} from "react-native-elements"

import {
    Picker
} from "@react-native-picker/picker"

import TestingViewModel, {
    Test,
    FirebaseImage,
} from "../ViewModel/TestingVM"

import styles from "./Stylesheets/Global"
import DGTestCaseRow from "./Component/DGTestCaseRow"
import UserTestsEditor from "./UserTestsEditor"
import TestEditor from "./TestEditor"
import DGCSVDownload from "./Component/DGCSVDownload"

let allUserTests = {}
let allTestSuites = {}
let allTestSuiteGroups = {}

const Tests = (props) => {

    const {
        firestore,
        navigation,
        initialScrolltop=0,
    } = props

    const [status, requestPermission] = ImagePicker.useCameraPermissions()
    const [userTests, setUserTests] = useState({})
    const [editingUserTests, setEditingUserTests] = useState(undefined)
    const [testCases, setTestCases] = useState({})
    const [csvData, setCSVData] = useState([])
    const [csvName, setCSVName] = useState("")
    const [testSuites, setTestSuites] = useState({})
    const [testSuiteGroups, setTestSuiteGroups] = useState({})
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showTestModal, setShowTestModal] = useState(false)
    const [showUserTestsModal, setShowUserTestsModal] = useState(false)
    const [image, setImage] = useState(null)
    const [VM, setVM] = useState(undefined)
    const [progress, setProgress] = useState(0)
    const [editingTest, setEditingTest] = useState(undefined)
    const [sortFilter, setSortFilter] = useState("description")
    const [selectedUserTests, setSelectedUserTests] = useState("none")
    const [scrolltop, setScrolltop] = useState(initialScrolltop)

    const setScrollViewY = (y) => {
        myscroll?.current && myscroll.current?.scrollTo({x: 0, y: y, animated: false})
    }

    const sortTests = (a, b) => {
        if(a.case[sortFilter] < b.case[sortFilter])
            return -1
        else if(a[sortFilter] > b[sortFilter])
            return 1

        return 0
    }

    const sortTableData = (t) => {
        if (t && !!Object.keys(t)?.length) {
            const tD = Object.values(t)
            tD.sort(sortTests)
            setTableData(tD)
        }
    }

    const clearTestDetails = () => {
        setImage(null)
        setEditingTest(undefined)
        setEditingUserTests(undefined)
    }

    const loadData = async () => {
        const testingVM = new TestingViewModel(firestore, requestPermission, status)
        setLoading(true)
        setVM(testingVM) 
        const tC = await testingVM.loadTestCases()
        const tS = await testingVM.loadTestSuites()
        const uT = await testingVM.loadUserTests()
        const tG = await testingVM.loadTestSuiteGroups()

        setLoading(false)

        setTestCases(tC)
        setTestSuites(tS)
        setUserTests(uT)
        setTestSuiteGroups(tG)

        allTestSuites = tS
        allTestSuiteGroups = tG

        if (uT[selectedUserTests])
            sortTableData(uT[selectedUserTests]?.tests)
        else
            setTableData([])
        setScrollViewY(scrolltop)
    }

    const failTest = async (t: Test, shouldUploadImage: boolean) => {
        t.pass = false
        const callback = (res) => saveTest({ t, ...res })
        if (image && shouldUploadImage)
            firestore?.uploadImage(image, callback, imageUploadProgressUpdate)
        else
            saveTest({ t })
    }

    const passTest = async (testCase, test) => {
        await VM.passTest(selectedUserTests, test)
        loadData()
    }

    const unPassTest = async (testCase, test) => {
        await VM.unPassTest(selectedUserTests, test)
        loadData()
    }

    const deleteTest = (test) => {
        VM.deleteTest(selectedUserTests, test)
    }

    const saveTest = async (data) => {
        const {
            t,
            downloadURL,
            imagePath,
        } = data

        if(imagePath) {
            const img: FirebaseImage = {
                imageURL: downloadURL,
                fullImagePath: imagePath,
            }
            t.image = img
        }

        setLoading(true)
        await doneEditing(false)
        await VM.saveTest(selectedUserTests, t)
        await loadData()
    }

    const addUserTests = async (tests) => {
        setLoading(true)
        setEditingUserTests(tests)
        await doneEditing(false)
        await VM.saveUserTests(tests)
        await loadData()
        setSelectedUserTests(tests.id)
        sortTableData(tests.tests)
    }

    const imageUploadProgressUpdate = (p) => {
        setProgress(p)
    }

    const doneEditing = async (reload=true) => {
        setShowUserTestsModal(false)
        setShowTestModal(false)
        if (reload) {
            await loadData()
        }
    }

    const openTestCases = () => {
        navigation.navigate("Test Case Editor")
    }

    const newUserTest = () => {
        setEditingUserTests(undefined)
        setSelectedUserTests("none")
        setTableData([])
    }

    const showUserTests = ()=> {
        if (!editingUserTests)
            clearTestDetails()

        setShowUserTestsModal(true)
    }

    useEffect(
        () => {
            loadData()
        }, [firestore]
    )
    const myscroll = useRef(null)
    return (
        <>
            {!showUserTestsModal && !showTestModal && (
                <ScrollView 
                    style={styles.container}
                    ref={myscroll}
                    onScroll={(event) => setScrolltop(event?.nativeEvent?.contentOffset?.y)}
                    scrollEventThrottle={3}
                >
                    <View 
                        style={styles.tableViewHeader}
                    >
                        <Text h3 style={styles.header}>Tests</Text>
                        <Icon 
                            name="plus-square" 
                            size={30} 
                            color="black"
                            onPress={showUserTests}
                        />
                    </View>
                    <Divider />
                    <View
                        style={{
                            flexDirection: "row",
                            paddingTop: 10,
                            paddingHorizontal: 0,
                            flex: 1,
                        }}
                    >
                        <View
                            style={{
                                flex: 4,
                                alignItems: "flex-start",
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                {Platform.OS === "web" && (
                                    <View style={{ marginLeft: 8 }} />
                                )}
                                <Picker
                                    selectedValue={selectedUserTests}
                                    itemStyle={{
                                        height: 45,
                                    }}
                                    style={styles.dgPickerStyle}
                                    onValueChange={
                                        (itemValue, itemIndex) => {
                                            setSelectedUserTests(itemValue)
                                            if (itemValue === "none") {
                                                setEditingUserTests(undefined)
                                                setTableData([])
                                            } else {
                                                setEditingUserTests(userTests[itemValue])
                                                if (userTests[itemValue]) {
                                                    setCSVData(VM.getTestsAsCSV(userTests[itemValue]))
                                                    setCSVName(`${userTests[itemValue].softwareName}-tests-${(new Date(parseInt(userTests[itemValue].updatedAt)).toISOString())}.csv`)
                                                    sortTableData(userTests[itemValue]?.tests)
                                                }
                                            }
                                        }
                                    }
                                >
                                    <Picker.Item label="New Software Test" value="none" />
                                    
                                    {!!Object.keys(userTests).length && Object.entries(userTests).map(
                                        ([userTestsID, userTestsObject]) => (
                                            <Picker.Item
                                                label={`${userTestsObject.softwareName} tests on ${(new Date(parseInt(userTestsObject.updatedAt)).toISOString())}`}
                                                value={userTestsID}
                                                key={userTestsID}
                                            />
                                        )
                                    )}
                                </Picker>
                                <TouchableOpacity
                                    style={{
                                        paddingHorizontal: 5,
                                    }}
                                    onPress={showUserTests}

                                >
                                    <Icon 
                                        name="folder-plus" 
                                        size={20} 
                                        color="black"
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View
                            style={{
                                flex: 2, 
                                alignItems: "flex-end",
                                marginRight: 5,
                            }}
                        >
                            <TouchableOpacity
                                onPress={openTestCases}
                                style={[styles.dgButton]}
                            >
                                <Text
                                    style={styles.dgButtonText}
                                >
                                    Test Cases
                                </Text>
                                <Icon 
                                    name="vial" 
                                    size={20} 
                                    color="rgb(255,50,50)"
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                    {!loading && !tableData.length && (
                        <View>
                            <Text h4 style={{ textAlign: "center", padding: 20 }}>
                                Create a New Software Test
                            </Text>
                            <Text h5 style={{ textAlign: "center", padding: 20 }}>
                                To add test cases, select the "plus" icon 
                            </Text>
                        </View>
                    )}
                    {loading ? <ActivityIndicator style={styles.loading} size="large" /> : (
                        !!tableData?.length && tableData.map(
                            test => (
                                <DGTestCaseRow 
                                    key={test.case.id}
                                    testCase={test.case}
                                    test={test}
                                    headerButtons={[{
                                        name: "dumpster-fire",
                                        action: (testCase, test) => {
                                            setImage(test?.image?.imageURL)
                                            setEditingTest(test)
                                            setShowTestModal(true)
                                        },
                                        size: 25,
                                        color: test.pass === false ? "rgb(255,50,50)" : "black"
                                    }, {
                                        name: "check",
                                        action: test.pass ? unPassTest : passTest,
                                        size: 25,
                                        color: test.pass ? "limegreen" : "black"
                                    }]}
                                />
                            )
                        )
                    )}
                    {!loading && !!tableData?.length && (
                        <DGCSVDownload 
                            data={csvData}
                            filename={csvName}
                        />
                    )}
                    <View
                        style={{
                            marginBottom: 120,
                        }}
                    />
                </ScrollView>
            )}
            {showUserTestsModal && (
                <ScrollView>
                    <UserTestsEditor 
                        doneEditing={doneEditing}
                        addUserTests={addUserTests}
                        allTestCases={testCases}
                        allTestSuites={testSuites}
                        allTestSuiteGroups={testSuiteGroups}
                        userTests={editingUserTests}
                        firestore={firestore}
                        testingVM={VM}
                        newUserTest={newUserTest}
                    />
                </ScrollView>
            )}
            {showTestModal && (
                <ScrollView>
                    <TestEditor
                        doneEditing={doneEditing}
                        test={editingTest}
                        firestore={firestore}
                        allTestCases={testCases}
                        image={image}
                        setImage={setImage}
                        failTest={failTest}
                        progress={progress}
                        firestore={firestore}
                        testingVM={VM}
                        deleteTest={deleteTest}
                        selectedUserTestsID={selectedUserTests}
                    />
                </ScrollView>
            )}
        </>
    )
}

export default Tests