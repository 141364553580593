import React, {
    useEffect,
    useState,
    useContext,
} from "react"
import SignIn from "./SignIn"
import NavigationRoot from "./Component/Navigation"
import ProjectTracker from "./ProjectTracker"
import UserSettings from "./UserSettings"
import TestCases from "./TestCases"
import Tests from "./Tests"
import Admin from "./Admin"
import LoadingView from "./LoadingView"
// import AccountsReceivable from './AccountsReceivable'
import AuthNavigator from "./AuthNavigator"
import {
    Context,
} from "../Model/FirebaseContext"

const DatageeMemberPortal = () => {

    const {
        firestore,
    } = useContext(Context)

    const [pages, setPages] = useState([{
        name: "Datagee",
        component: <LoadingView />,
    }])
    
    const isAdmin = async () => {
        const s = await firestore.read("Admin", firestore.user.uid)

        return s.uid && s.uid === firestore.user.uid
    }

    const addAdminView = async () => {
        const userIsAdmin = await isAdmin()
        const p = [
            {
                name: "Tests", 
                component: <Tests firestore={firestore} />,
            },
            {
                name: "Test Case Editor", 
                component: <TestCases firestore={firestore} />,
            },
            {
                name: "Project Tracker", 
                component: <ProjectTracker firestore={firestore} />,
            },
            {
                name: "User Settings", 
                component: <UserSettings firestore={firestore} />,
            },
        ]
        if (userIsAdmin) {
            p.push({
                name: "Admin",
                component: <Admin firestore={firestore} />,
            })
        }
        setPages(p)
    }

    useEffect(
        () => {

            if (firestore.user) {
                addAdminView()
            }

        }, [firestore]
    )

    return (
        <>
            {(firestore.user && !!pages.length) ? (
                <NavigationRoot
                    initialIndex={0}
                    pages={pages}
                />
            ) : (
                <AuthNavigator firestore={firestore} />
            )}
        </>
    )
}

export default DatageeMemberPortal