import React from "react"
import { View } from "react-native"
import { Card, Text } from "react-native-elements"
import { TouchableOpacity } from "react-native-gesture-handler"

const DGCard = (props) => {
    return (
        <Card style={{ width: "100px" }}>
            <Card.Title>{props.title}</Card.Title>
            <Card.Divider/>
            <Text>Assignee: {props.assignee}</Text>
            <Text>Hours Spent: {props.hours.toFixed(2)}</Text>
            <Text>Story Points: {props.storyPoints}</Text>
            <Text>Status: {props.status}</Text>
            <View style={{ paddingTop: 5 }}>
                {props.timelogNotes && props.timelogNotes.map(
                    (note, i) => {
                        return (
                            <TouchableOpacity key={i}>
                                <Text>{i + 1}. {note[0]}</Text>
                                <Text>Started At: {note[2]}</Text>
                                <Text style={{ 
                                    paddingBottom: 5,
                                }}>Hours: {note[1].toFixed(2)}</Text>
                            </TouchableOpacity>
                        )
                    }
                )}
            </View>
        </Card>
    )
}

export default DGCard