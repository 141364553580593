import {
    StyleSheet,
    Platform,
} from "react-native"

const styles = StyleSheet.create({
    DGHR: {
        height: 1,
        backgroundColor: "rgba(170,170,170,0.5)",
    },
    dgPickerStyle: {
        height: 45,
        minWidth: 230,
        textAlign: "center",
        borderWidth: 0,
        borderBottomWidth: Platform.OS === "web" ? 0.5 : 0,
    },
    tableRow: {
        paddingVertical: 12,
        paddingHorizontal: 8,
    },
    tableRowHeading: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: 10,
        flex: 1,
    },
    tableRowHeadingDescription: {
        flex: 4,
        fontWeight: "bold",
        fontSize: 16,
        flexWrap: "wrap",
    },
    tableRowHeadingAction: {
        flex: 2,
        alignItems: "flex-end",
    },
    tableRowImage: {
        width: 200,
        height: 200,
        alignSelf: "center",
        borderRadius: 3,
        marginBottom: 15,
        shadowOffset: {
            width: 20,
            height: 20,
        },
        shadowColor: "black",
        shadowOpacity: 0.5,
        shadowRadius: 0.3,
    },
    tableRowDetails: {
        marginVertical: 30,
    },
    tableViewHeader: {
        flexDirection: "row",
        alignItems: "center",
        padding: 5,
        paddingBottom: 10,
    },
    dgButton: {
        flexDirection: "row",
        alignItems: "center",
        padding: 12,
        paddingVertical: 12,
        borderWidth: 1,
        maxWidth: 130,
        borderRadius: 3,
        borderColor: "rgb(255,50,50)",
        color: "rgb(255,50,50)",
    },
    dgButtonText: {
        color: "rgb(255,50,50)",
        paddingRight: 10,
    },
    dgButtonIcon: {
        color: "rgb(255,50,50)",
    },
    container: {
        padding: 10,
        flexDirection: "column",
    },
    header: {
        paddingBottom: 10,
        flex: 3,
    },
    loading: {
        padding: 20
    },
    modal: {
        padding: 30,
        display: "flex",
        flex: 1,
        justifyContent: "flex-start",
        paddingTop: 70,
    },
    modalImage: {
        width: 200, 
        height: 200,
        borderRadius: 3,
        alignSelf: "center",
        shadowOffset: {
            width: 20,
            height: 20,
        },
        shadowColor: "black",
        shadowOpacity: 0.5,
        shadowRadius: 0.3,
        // marginBottom: 20,
    },
    modalUI: {
        justifyContent: "space-around",
    },
})

export default styles