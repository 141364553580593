import React from "react"
import { Divider, Text } from "react-native-elements"
import {
    Button,
    View,
    StyleSheet
} from "react-native"
import DGHR from "./Component/DGHR"

const UserSettings = (props) => {
    const {
        firestore,
    } = props

    return (
        <View style={styles.container}>
            <Text h3 style={styles.header}>User Settings</Text>
            <Divider />
            <View 
                style={{ padding: 20 }}
            >
                <Text style={{ fontWeight: "300" }}>Datagee Member Portal Version 1.1.6</Text>
                <Text style={{ fontWeight: "300", marginBottom: 5, marginTop: 100 }}>Login Options</Text>
                <DGHR />
                <Button title="Sign Out" onPress={firestore?.signOutUser} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 10,
        flexDirection: "column",
    },
    header: {
        paddingBottom: 10,
    }
})

export default UserSettings