import React, { 
    useEffect, 
    useState, 
    useRef 
} from "react"

import {
    ActivityIndicator, 
    View, 
    ScrollView, 
    Platform,
    TouchableOpacity,
} from "react-native"

import {
    Picker
} from "@react-native-picker/picker"

import {
    Text, 
    Button, 
    Divider,
} from "react-native-elements"

import ProjectTrackerViewModel from "../ViewModel/ProjectTrackerVM"
import DGTaskCard from "./Component/DGTaskCard"
import DateTimePickerModal from "react-native-modal-datetime-picker"

let endDateNoState = undefined
let apiCallStatusNoState = undefined
let pageNumber = 1

/**
 *
 * @param props
 */
function ProjectTracker(props) {

    const db = props?.firestore

    const scrollRef = useRef()

    const [VM, setVM] = useState(undefined)
    const [apiCallStatus, setStatus] = useState(undefined)
    const [isStartDatePickerVisible, setStartDatePickerVisibility] = useState(false)
    const [isEndDatePickerVisible, setEndDatePickerVisibility] = useState(false)
    const [startDate, selectStartDate] = useState(undefined)
    const [startDateString, selectStartDateString] = useState("")
    const [endDate, selectEndDate] = useState(undefined)
    const [endDateString, selectEndDateString] = useState("")
    const [calendarMessage, setCalendarMessage] = useState("Select a start and end date to see progress on your projects")
    const [projects, setProjects] = useState([])
    const [projectIndex, setProjectIndex] = useState(0)
    const [coefficients, setCoefficients] = useState({})

    const handleStartDateChange = (date) => {
        const [d, ds] = VM.formatDate(date)
        selectStartDate(d)
        selectStartDateString(ds)
        setStartDatePickerVisibility(false)
        dateChanged(d, endDate, ds, endDateString)

    }
    const handleEndDateChange = (date) => {
        const [d, ds] = VM.formatDate(date)
        selectEndDate(d)
        selectEndDateString(ds)
        endDateNoState = ds
        setEndDatePickerVisibility(false)
        dateChanged(startDate, d, startDateString, ds)
    }
    const scrollToTop = () => {
        scrollRef.current?.scrollTo({
            y: 0,
            animated: true,
        })
    }
    const processAPIData = async (newData) => {
        const jiraData = newData.jiraData
        const status = apiCallStatusNoState || {}
        Object.keys(jiraData).forEach(
            (taskID) => {
                if(status[taskID]) {
                    status[taskID].totalHours += jiraData[taskID].totalHours
                    status[taskID].timelogNotes.concat(jiraData[taskID].timelogNotes)
                } else {
                    status[taskID] = jiraData[taskID]
                }
            }
            
        )
        setStatus(status)
        setProjects(newData.projects)
        setProjectIndex(0)
        apiCallStatusNoState = status
        if(newData.more) {
            pageNumber++
            const pageResult = await loadPage(pageNumber)
            console.log(`Page ${pageNumber} result ${pageResult ? "succeeded" : "failed"}`)
        } else {
            pageNumber = 1
            setCalendarMessage(
                Object.keys(status).length === 0
                    ? "No results found in the specified range"
                    : ""
            )
        }
    }
    
    const loadPage = async (p) => {     
        if(!p) {
            pageNumber = 1
            return false
        }
        const pageAsInt = parseInt(p)
        if(pageAsInt < 1) {
            pageNumber = 1
            return false
        }
        setCalendarMessage("")
        setStatus(undefined)
        pageNumber = pageAsInt
        const apiResponse = await VM.getProjectData( 
            startDateString, 
            endDateNoState, 
            pageAsInt
        )
        await processAPIData(apiResponse)
        return true
    }
    const dateChanged = async (s, e, sds, eds) => {
        setStatus(undefined)
        apiCallStatusNoState = undefined
        if (s && e) {
            if (e - s >= 0) {
                setCalendarMessage("")
                const apiResponse = await VM.getProjectData(
                    sds, 
                    eds, 
                    1,
                )
                processAPIData(apiResponse)
            } else {
                setCalendarMessage("Select a start date that occurs before the end date.")
            }
        }
    }

    const generateProjectCoefficients = () => {
        let c = VM.generateCoefficientsForProject(apiCallStatus, projects[projectIndex])
        setCoefficients(c)
    }

    const generateAllCoefficients = () => {
        let c = VM.generateCoefficientsAcrossAllProjects(apiCallStatus, projects)
        setCoefficients(c)
    }

    useEffect(
        () => {
            const newVM = new ProjectTrackerViewModel(db, Platform.OS)
            setVM(newVM)
        }, []
    )
    return (
        <View>
            <TouchableOpacity onPress={scrollToTop}>
                <Text h3 style={{ padding: 10 }}>Project Tracker</Text>
            </TouchableOpacity>
            <Divider />
            <ScrollView ref={scrollRef} style={{ marginBottom: 140, paddingTop: 30 }}>
                <>
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1, alignItems: "center" }}>
                            <Text h4>Start Date</Text>
                            {(Platform.OS === "ios" || Platform.OS === "android") && 
                            <>
                                <Button 
                                    title="Select Start Date" 
                                    onPress={()=>{setStartDatePickerVisibility(true)}}
                                    type="outline"
                                />
                                <DateTimePickerModal
                                    isVisible={isStartDatePickerVisible}
                                    mode="date"
                                    onConfirm={handleStartDateChange}
                                    onCancel={()=>{setStartDatePickerVisibility(false)}}
                                    date={startDate || new Date()}
                                />
                            </>}
                            {Platform.OS !== "ios" && Platform.OS !== "android" && 
                                <input type="date" onChange={(e)=>{handleStartDateChange(e.target.value)}}></input>
                            }
                            <Text h4>{startDateString}</Text>
                        </View>
                        <View style={{ flex: 1, alignItems: "center" }}>
                            <Text h4>End Date</Text>
                            {(Platform.OS === "ios" || Platform.OS === "android") && 
                            <>
                                <Button 
                                    title="Select End Date" 
                                    onPress={()=>{setEndDatePickerVisibility(true)}}
                                    type="outline"
                                />
                                <DateTimePickerModal
                                    isVisible={isEndDatePickerVisible}
                                    mode="date"
                                    onConfirm={handleEndDateChange}
                                    onCancel={()=>{setEndDatePickerVisibility(false)}}
                                    date={endDate || new Date()}
                                />
                            </>}
                            {Platform.OS !== "ios" && Platform.OS !== "android" && 
                                <input type="date" onChange={(e)=>{handleEndDateChange(e.target.value)}}></input>
                            }
                            <Text h4>{endDateString}</Text>
                        </View>
                    </View>
                    {projects && projects.length > 0 &&
                    <View style={{ alignItems: "center" }}>
                        <View style={{ width: 50, margin: 10 }}>
                            <Picker
                                selectedValue={projects[projectIndex]}
                                onValueChange={(itemValue, itemIndex) =>
                                    setProjectIndex(itemIndex)
                                }>
                                {projects.map((project, i) => {
                                    return <Picker.Item key={project} label={project} value={project} />
                                })}
                            </Picker>
                        </View>
                    </View>
                    }
                    {calendarMessage !== "" && <Text h4 style={{ textAlign: "center", padding:10 }}>
                        {calendarMessage}
                    </Text>}
                    <View style={{ paddingBottom: 200 }}>
                        {calendarMessage === "" && !apiCallStatus && (
                            <View style={{ padding: 3, alignItems: "center" }}>
                                <Text style={{ paddingBottom: 10 }} h4>Loading Results</Text>
                                <ActivityIndicator size="large" />
                            </View>
                        )}
                        {apiCallStatus && Object.keys(apiCallStatus).length > 0 && projects[projectIndex] && (
                            <Button type="outline" 
                                onPress={generateProjectCoefficients}
                                title={`Generate Coefficients for ${projects[projectIndex]}`}
                            />
                        )}
                        {apiCallStatus && Object.keys(apiCallStatus).length > 0 && projects.length > 1 && (
                            <Button type="outline" 
                                onPress={generateAllCoefficients}
                                title={"Generate Coefficients Across all projects"}
                            />
                        )}
                        <View 
                            style={{
                                padding: 10
                            }}
                        >
                            {!!Object.keys(coefficients).length && <Text h4>Story Point Coefficients</Text>}
                            {!!Object.keys(coefficients).length && Object.keys(coefficients).map(
                                developer => (
                                    <Text 
                                        key={developer}
                                        style={{
                                            padding: 2
                                        }}
                                    >
                                        {developer}: {coefficients[developer]?.toFixed(3)}
                                    </Text>
                                )
                            )}
                        </View>
                        {
                            apiCallStatus && Object.keys(apiCallStatus).map((key) => {
                                return key.includes(projects[projectIndex]) && (
                                    <DGTaskCard 
                                        key={key} 
                                        assignee={
                                            apiCallStatus[key].assignee ? apiCallStatus[key].assignee.displayName : "No Assignee"
                                        } 
                                        hours={apiCallStatus[key].totalHours}
                                        storyPoints={apiCallStatus[key].storyPoints}
                                        status={apiCallStatus[key].status}
                                        title={key}
                                        timelogNotes={apiCallStatus[key].timelogNotes}
                                    />
                                )
                            })
                        }
                    </View>
                </>
            </ScrollView>
        </View>
    )
}

export default ProjectTracker