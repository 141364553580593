import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
    Text,
} from "react-native"
import SignUp from "./SignUp"
import SignIn from "./SignIn"
import LoginHelp from "./LoginHelp"
import LoadingView from "./LoadingView"
const Stack = createNativeStackNavigator()

const AuthNavigator = (props) => {
    const {
        firestore
    } = props
  
    return (
        firestore?.user === null ? (
            <Stack.Navigator 
                initialRouteName={"Log In"} 
                screenOptions={{
                    gestureEnabled: false,
                    headerShown: false,
                }}
            >
                <Stack.Screen name="Log In">
                    {
                        props => <SignIn {...props} firestore={firestore} />
                    }
                </Stack.Screen>
                <Stack.Screen name="Sign Up">
                    {
                        props => <SignUp {...props} firestore={firestore} />
                    }
                </Stack.Screen>
                <Stack.Screen name="Login Help">
                    {
                        props => <LoginHelp {...props} firestore={firestore} />
                    }
                </Stack.Screen>
            </Stack.Navigator>
        ) : (
            // <LoadingView />
            <Text>{JSON.stringify(firestore)}</Text>
        )
    )
}

export default AuthNavigator