import React, {
    useState,
    useRef,
} from "react"

import {
    View,
    TextInput,
    ScrollView,
    SafeAreaView,
    KeyboardAvoidingView,
    ActivityIndicator,
    TouchableWithoutFeedback,
    Image,
    Keyboard,
    Platform
} from "react-native"

import {
    Text,
    Button,
} from "react-native-elements"

import styles from "./Stylesheets/Auth"
import logo from "../assets/dgClear.png"

const SignUpScreen = (props) => {

    const {
        firestore,
        navigation
    } = props
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const lastNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()

    const signUpWithEmail = async () => {
        setLoading(true)
        const e = await firestore?.signUpWithEmail(email, password, firstName, lastName)
        if (e) {
            setError(e)
            setLoading(false)
        }
    }

    const handleKeyboardDismiss = () => {
        if (Platform.OS !== "web")
            Keyboard.dismiss()
    }

    return (
        <>
            <KeyboardAvoidingView 
                behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
                {loading &&
                <ActivityIndicator size={"large"} />
                }
                <TouchableWithoutFeedback onPress={handleKeyboardDismiss}>
                    <SafeAreaView style={styles.container}>
                        <ScrollView style={styles.form}>
                            <Image source={logo} style={styles.logo}/>
                            <Text h3 style={styles.header}>Sign Up</Text>
                            {loading &&
                            <ActivityIndicator size={"large"} />
                            }
                            <Text
                                style={styles.error}
                            >
                                {error}
                            </Text>
                            <TextInput
                                style={styles.input}
                                placeholder="First Name"
                                placeholderTextColor="#B1B1B1"
                                returnKeyType="next"
                                textContentType="name"
                                value={firstName}
                                onChangeText={setFirstName}
                                onSubmitEditing={() => lastNameRef.current.focus()}
                            />
                            <TextInput
                                style={styles.input}
                                placeholder="Last Name"
                                placeholderTextColor="#B1B1B1"
                                returnKeyType="next"
                                textContentType="name"
                                value={lastName}
                                onChangeText={setLastName}
                                ref={lastNameRef}
                                onSubmitEditing={() => emailRef.current.focus()}
                            />
                            <TextInput
                                style={styles.input}
                                placeholder="Email"
                                placeholderTextColor="#B1B1B1"
                                returnKeyType="next"
                                keyboardType="email-address"
                                textContentType="emailAddress"
                                value={email}
                                onChangeText={setEmail}
                                ref={emailRef}
                                onSubmitEditing={() => passwordRef.current.focus()}
                            />
                            <TextInput
                                style={styles.input}
                                placeholder="Password"
                                placeholderTextColor="#B1B1B1"
                                returnKeyType="done"
                                textContentType="newPassword"
                                secureTextEntry={true}
                                value={password}
                                onChangeText={setPassword}
                                ref={passwordRef}
                                onSubmitEditing={signUpWithEmail}
                            />
                            <Button
                                onPress={signUpWithEmail}
                                title="Sign Up"
                            />
                            <Text
                                style={styles.helpLink}
                                onPress={() => {
                                    navigation.navigate("Log In")
                                }}
                            >
                                Already have an Account?
                            </Text>
                        </ScrollView>
                    </SafeAreaView>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
        </>
    )
}

export default SignUpScreen