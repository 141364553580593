import React, {
    useState,
    useEffect,
} from "react"
import { StatusBar } from "expo-status-bar"
import { NavigationContainer, DefaultTheme } from "@react-navigation/native"
import {
    ActivityIndicator
} from "react-native"
import {
    FirebaseContextProvider
} from "./Model/FirebaseContext"
import AppContainer from "./View/AppContainer"
import DatageeMemberPortal from "./View/DatageeMemberPortal"
import { SafeAreaView } from "react-native"

const MyTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: "white"
    },
}

/**
 *
 */
export default function App () {

    return (
        <NavigationContainer 
            fallback={<ActivityIndicator size="large" />}
            theme={MyTheme}
        >
            <StatusBar style="default" />
            <FirebaseContextProvider>
                <DatageeMemberPortal />
            </FirebaseContextProvider>
        </NavigationContainer>
    )
}